import {
  IYDocWSMessage_SyncDoc,
  WSSCode,
  YDocSyncMessage,
  YDocWSClientMessage,
  YDocWSMessageType,
  YDocWSServerMessage,
} from '@rmvw/x-common';
import { Observable } from 'lib0/observable';

import Logger from '../observability/Logger';

type YDocWSEventListener = (msg: YDocSyncMessage) => void;

type WSState = 'DISCONNECTED' | 'CONNECTING' | 'AWKWARDLY_SHAKING_HANDS' | 'CONNECTED';

const BASE_RECONNECT_INTERVAL_MS = 125;
const MAX_RECONNECT_INTERVAL_MS = 5000;
const HEARTBEAT_INTERVAL_MS = 30000;

/**
 * WebSockets provider
 */
export default class YDocWSTransport extends Observable<'connect'> {
  private _accessToken: string;
  private _msgQueue: YDocWSClientMessage[] = [];
  private _pingTimeout?: number;
  private _reconnectCount = 0;
  private _reconnectTimer?: number;
  private _state: WSState = 'DISCONNECTED';
  private _ws?: WebSocket;

  private _listeners: Map<string, Set<YDocWSEventListener>> = new Map();

  private _wsOpenEventListener = (event: Event) => {
    // We've established a new connection, now let's confirm that we have permission to continue
    this._state = 'AWKWARDLY_SHAKING_HANDS';
    this._send({ type: YDocWSMessageType.CONNECTION_INIT, params: { t: this._accessToken } });
    this._pingHeartbeat();
  };

  private _wsCloseEventListener = (event: CloseEvent) => {
    Logger.info(`[YDocWSTransport] Connection closed, code: ${event.code}`);
    this._disconnect();

    // Attempt to reconnect except if we receive an UNAUTHORIZED response
    if (event.code !== WSSCode.UNAUTHORIZED) {
      this._reconnect();
    }
  };

  private _wsErrorEventListener = (event: Event) => {
    Logger.error(event, '[YDocWSTransport] Connection error');
    this._disconnect();
    this._reconnect();
  };

  private _wsMessageEventListener = (event: MessageEvent<string>) => {
    let msg: YDocWSServerMessage;
    try {
      msg = JSON.parse(event.data);
    } catch (e) {
      return; // JSON parse error
    }

    switch (msg.type) {
      case YDocWSMessageType.CONNECTION_ACK: {
        // Successful handshake
        this._state = 'CONNECTED';

        // Flush any queued messages
        this._msgQueue.forEach((msg) => this.send(msg));
        this._msgQueue = [];

        // Fire onConnect handlers
        this.emit('connect', []);
        break;
      }

      case YDocWSMessageType.PING: {
        this.send({ type: YDocWSMessageType.PONG });
        this._pingHeartbeat();
        break;
      }

      case YDocWSMessageType.SYNC_DOC: {
        const { docId } = msg;
        const listeners = this._listeners.get(docId);
        if (!listeners) {
          return;
        }

        listeners.forEach((listener) => {
          try {
            listener((<IYDocWSMessage_SyncDoc>msg).msg);
          } catch (e) {
            Logger.error(e as Error, '[YDocWSTransport] Listener error');
          }
        });
        break;
      }

      default: {
        Logger.error(`[YDocWSTransport] Unknown event: ${msg}`);
        break;
      }
    }
  };

  constructor(props: { accessToken: string }) {
    super();
    this._accessToken = props.accessToken;
  }

  send(msg: YDocWSClientMessage) {
    if (this._state !== 'CONNECTED') {
      this._msgQueue.push(msg); // Websocket not connected...queue message for deferred send
    } else {
      this._send(msg);
    }
  }

  private _send(msg: YDocWSClientMessage) {
    this._ws?.send(JSON.stringify(msg));
  }

  addEventListener(docId: string, listener: YDocWSEventListener): this {
    // Implicitly connect
    this.connect();

    // Add listener
    this._listeners.set(docId, (this._listeners.get(docId) ?? new Set()).add(listener));

    // Notify server of our intent to listen to this doc
    this.send({ type: YDocWSMessageType.SUBSCRIBE, docId });
    return this;
  }

  removeEventListener(docId: string, listener: YDocWSEventListener): this {
    this._listeners.get(docId)?.delete(listener);
    if (this._listeners.get(docId)?.size === 0) {
      this._listeners.delete(docId);
    }

    // Notify server of our unsubscribe request
    this.send({ type: YDocWSMessageType.UNSUBSCRIBE, docId });

    return this;
  }

  connect() {
    if (this._ws) {
      return;
    }

    if (this._reconnectTimer) {
      clearTimeout(this._reconnectTimer);
      this._reconnectTimer = undefined;
    }

    try {
      this._ws = new WebSocket(process.env['S_API_YDOC_WS_URL'] ?? '');
      this._ws.addEventListener('open', this._wsOpenEventListener);
      this._ws.addEventListener('close', this._wsCloseEventListener);
      this._ws.addEventListener('message', this._wsMessageEventListener);
      this._ws.addEventListener('error', this._wsErrorEventListener);
      this._state = 'CONNECTING';
    } catch (e) {
      Logger.error(e as Error, '[YDocWSTransport.connect]');
      this._disconnect();
      this._reconnect();
    }
  }

  disconnect() {
    this._disconnect();
    this._listeners.clear(); // clear all listeners on explicit disconnect
  }

  private _disconnect() {
    if (this._reconnectTimer) {
      clearTimeout(this._reconnectTimer);
      this._reconnectTimer = undefined;
    }

    if (this._pingTimeout) {
      clearTimeout(this._pingTimeout);
      this._pingTimeout = undefined;
    }

    this._ws?.close();
    this._ws?.removeEventListener('open', this._wsOpenEventListener);
    this._ws?.removeEventListener('close', this._wsCloseEventListener);
    this._ws?.removeEventListener('message', this._wsMessageEventListener);
    this._ws?.removeEventListener('error', this._wsErrorEventListener);
    this._ws = undefined;
    this._state = 'DISCONNECTED';
  }

  private _reconnect() {
    if (this._reconnectTimer) {
      return;
    }

    this._reconnectCount++;
    this._reconnectTimer = setTimeout(() => {
      this._reconnectTimer = undefined;
      this.connect();
    }, Math.min(MAX_RECONNECT_INTERVAL_MS, Math.pow(2, this._reconnectCount) * BASE_RECONNECT_INTERVAL_MS)) as unknown as number;
  }

  private _pingHeartbeat() {
    if (this._pingTimeout) {
      clearTimeout(this._pingTimeout);
    }

    // Timeout set to server heartbeat interval + 1s
    this._pingTimeout = setTimeout(() => {
      Logger.debug('[YDocWSTransport] Ping timeout. Tickling connection...');
      this._disconnect();
      this._reconnect();
    }, HEARTBEAT_INTERVAL_MS + 1000) as unknown as number;
  }
}
