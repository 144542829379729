import * as React from 'react';

const PRIOR_SESSIONS_KEY = 'assist_sessions';

function _getSessionKey(scopeIds: ReadonlyArray<string>) {
  return scopeIds.length ? [...scopeIds].sort().join(',') : 'default';
}

interface IInitialPromptScope {
  prompt?: string;
  scopeIds?: string[];
}

export interface IAssistantManager {
  /**
   * Currently set scope ids
   */
  defaultScopeIds: string[];

  /**
   * Set the scope IDs for the assistant to use
   */
  setDefaultScopeIds: (threadIds: string[]) => void;
}

export const defaultContext: IAssistantManager = {
  defaultScopeIds: [],
  setDefaultScopeIds: () => null,
};

export const AssistantContext = React.createContext<IAssistantManager>(defaultContext);

export default function AssistantProvider(props: React.PropsWithChildren<unknown>) {
  // Extrinsically controlled state:
  const [defaultScopeIds, _setDefaultScopeIds] = React.useState<string[]>([]);

  const assistantManager = React.useMemo<IAssistantManager>(
    () => ({
      defaultScopeIds,
      setDefaultScopeIds: _setDefaultScopeIds,
    }),
    [defaultScopeIds, _setDefaultScopeIds]
  );

  return <AssistantContext.Provider value={assistantManager}>{props.children}</AssistantContext.Provider>;
}

export interface IUseAssistantProps {
  /**
   * Initial scope ID for the assistant
   */
  scopeId?: string;
}

export const useAssistant = (props?: IUseAssistantProps): IAssistantManager => {
  const assistant = React.useContext(AssistantContext);

  const { setDefaultScopeIds } = assistant;
  React.useEffect(() => {
    if (!props?.scopeId) {
      return;
    }
    setDefaultScopeIds([props.scopeId]);
    return () => setDefaultScopeIds([]); // Clear the scope Ids when the component unmounts
  }, [setDefaultScopeIds, props?.scopeId]);

  return assistant;
};
