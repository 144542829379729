import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';

const _InnerDiv = styled.div`
  user-select: none;
`;

export interface IVoidBlockElementProps
  extends Omit<RenderElementProps, 'children' | 'element'>,
    React.HTMLAttributes<HTMLSpanElement> {
  slateChildren: React.ReactNode;
}

export default function VoidBlockElement({
  attributes,
  slateChildren,
  ...props
}: React.PropsWithChildren<IVoidBlockElementProps>) {
  if (attributes['data-slate-inline']) {
    throw new Error('This component should only be used for block elements!');
  }

  //
  // IMPORTANT: DO NOT REMOVE THE DIV WRAPPERS BELOW
  // SlateJS is EXTREMELY sensitive to the DOM structure of void elements it renders.
  //
  return (
    <div {...attributes} {...props}>
      <div contentEditable={false}>
        <_InnerDiv>{props.children}</_InnerDiv>
      </div>
      {slateChildren}
    </div>
  );
}
