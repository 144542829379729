import { gql, useQuery } from '@apollo/client';

import { useInboxUnreadCount_InboxUnreadCountQuery } from './___generated___/useInboxUnreadCount.types';

const InboxUnreadCountQuery = gql`
  query useInboxUnreadCount_InboxUnreadCountQuery {
    inboxUnreadCount
  }
`;

export function useInboxUnreadCount(skip?: boolean) {
  const { data } = useQuery<useInboxUnreadCount_InboxUnreadCountQuery>(InboxUnreadCountQuery, { skip: skip });
  return data?.inboxUnreadCount ?? 0;
}
