import * as React from 'react';
import { Link } from 'react-router-dom';
import styled, { Variant } from 'styled-components';

import { CameraIcon, DiscussionIcon, FileIcon, MessageIcon, PersonIcon, ReactionIcon } from '../Icon';
import { ConditionalWrapper } from '../lib/ConditionalWrapper';

const _Container = styled.div<{ variant?: MetadataVariant }>`
  align-items: center;
  background-color: ${({ theme, variant }) =>
    variant ? theme.color.variant[variant].background : theme.color.background400};
  border: 1px solid
    ${({ theme, variant }) => (variant ? theme.color.variant[variant].border : theme.color.secondaryBorder)};
  border-radius: 6px;
  color: ${({ theme, variant }) => (variant ? theme.color.variant[variant].text : theme.color.color)};
  display: inline-flex;
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  gap: 4px;
  line-height: 1;
  padding: 4px 10px;
`;

export type MetadataType = 'replies' | 'reactions' | 'videos' | 'notes' | 'participants' | 'discussions';
export type MetadataVariant = Variant;

function mapTypeToIcon(type: MetadataType) {
  switch (type) {
    case 'discussions':
      return DiscussionIcon;
    case 'notes':
      return FileIcon;
    case 'participants':
      return PersonIcon;
    case 'reactions':
      return ReactionIcon;
    case 'replies':
      return MessageIcon;
    case 'videos':
      return CameraIcon;
  }
}

export interface IMetadataBadgeProps {
  count: number | string;
  to?: string;
  type: MetadataType;
  variant?: MetadataVariant;
}

function MetadataBadge({ count, to, type, variant }: IMetadataBadgeProps) {
  const Icon = mapTypeToIcon(type);

  return (
    <ConditionalWrapper condition={!!to} wrapper={({ children }) => <Link to={to!}>{children}</Link>}>
      <_Container variant={variant}>
        {count}
        <Icon aria-label={type} />
      </_Container>
    </ConditionalWrapper>
  );
}

export default MetadataBadge;
