import * as React from 'react';

import AudioUtils, { IAudioPlayerOptions } from '../lib/utils/AudioUtils';

export default function useSoundEffects() {
  return React.useMemo(() => {
    return {
      accessRequest: (options?: IAudioPlayerOptions) =>
        AudioUtils.play(require('../../static/sounds/notifications/access-request.mp3'), options),
      accessGranted: (options?: IAudioPlayerOptions) =>
        AudioUtils.play(require('../../static/sounds/notifications/access-granted.mp3'), options),
      discussionCreated: (options?: IAudioPlayerOptions) =>
        AudioUtils.play(require('../../static/sounds/notifications/discussion-created.mp3'), options),
      invitedToMeeting: (options?: IAudioPlayerOptions) =>
        AudioUtils.play(require('../../static/sounds/notifications/invited-to-meeting.mp3'), options),
      joinMeeting: (options?: IAudioPlayerOptions) =>
        AudioUtils.play(require('../../static/sounds/notifications/join-meeting.mp3'), options),
      leaveMeeting: (options?: IAudioPlayerOptions) =>
        AudioUtils.play(require('../../static/sounds/notifications/leave-meeting.mp3'), options),
      meetingStarted: (options?: IAudioPlayerOptions) =>
        AudioUtils.play(require('../../static/sounds/notifications/meeting-started.mp3'), options),
      newMessage: (options?: IAudioPlayerOptions) =>
        AudioUtils.play(require('../../static/sounds/notifications/new-message.mp3'), options),
      newReaction: (options?: IAudioPlayerOptions) =>
        AudioUtils.play(require('../../static/sounds/notifications/new-reaction.mp3'), options),
    };
  }, []);
}
