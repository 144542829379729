import { isSameDay } from 'date-fns';
import * as React from 'react';
import styled from 'styled-components';

import Timestamp, { TimestampFormat } from '../../Timestamp';

const _Timestamp = styled(Timestamp)``;

const _Timespan = styled.div`
  color: ${({ theme }) => theme.color.secondaryColor};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  gap: 4px;

  ${_Timestamp} {
    color: inherit;
    font-size: inherit;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

export interface IAssistantFilterTimeSpanProps {
  startTime?: Date;
  endTime?: Date;
}

export default function AssistantFilterTimeSpan({ startTime, endTime }: IAssistantFilterTimeSpanProps) {
  // Both startTime and endTime are on the same day: show "Today" or the endTime
  if (startTime && isSameDay(startTime, endTime ?? new Date())) {
    return (
      <_Timespan>
        {isSameDay(endTime ?? new Date(), new Date()) ? (
          <strong>Today</strong>
        ) : (
          <_Timestamp format={TimestampFormat.SHORT} timestamp={endTime ?? new Date()} />
        )}
      </_Timespan>
    );
  }

  // If startTime is not provided or zero, and endTime is null, show "All"
  if (!startTime?.valueOf() && !endTime) {
    return (
      <_Timespan>
        <strong>All</strong>
      </_Timespan>
    );
  }

  return (
    <_Timespan>
      {startTime?.valueOf() ? <_Timestamp format={TimestampFormat.SHORT} timestamp={startTime} /> : null}
      <span>&ndash;</span>
      {endTime ? (
        isSameDay(endTime, new Date()) ? (
          <strong>Today</strong>
        ) : (
          <_Timestamp format={TimestampFormat.SHORT} timestamp={endTime} />
        )
      ) : (
        <strong>Today</strong>
      )}
    </_Timespan>
  );
}
