import { ApolloError, QueryHookOptions, gql, useQuery } from '@apollo/client';

import {
  AssistantSessionQuery,
  AssistantSessionQueryVariables,
} from './___generated___/useAssistantSessionQuery.types';
import AssistantResponseMessage from './AssistantResponseMessage';
import AssistantUserMessage from './AssistantUserMessage';

export const AssistantMessageFragmentGQL = gql`
  ${AssistantUserMessage.fragment}
  ${AssistantResponseMessage.fragment}
  fragment CF_AssistantMessageFragment on IAssistantMessage {
    ...CF_AssistantUserMessage
    ...CF_AssistantResponseMessage
  }
`;

export const AssistantSessionFragmentGQL = gql`
  ${AssistantMessageFragmentGQL}
  fragment CF_AssistantSessionFragment on AssistantSession {
    id
    contextFilters {
      id
      endTime
      scopedThreads {
        id
      }
      startTime
    }
    messages {
      ...CF_AssistantMessageFragment
    }
    name
  }
`;

export const AssistantSessionQueryGQL = gql`
  ${AssistantSessionFragmentGQL}
  query AssistantSessionQuery($id: ID!) {
    assistantSession(id: $id) {
      ...CF_AssistantSessionFragment
    }
  }
`;

export function useAssistantSessionQuery(
  input: AssistantSessionQueryVariables,
  options?: Omit<QueryHookOptions<AssistantSessionQuery, AssistantSessionQueryVariables>, 'variables'>
): {
  loading: boolean;
  error?: ApolloError;
  data?: AssistantSessionQuery;
} {
  return useQuery<AssistantSessionQuery, AssistantSessionQueryVariables>(AssistantSessionQueryGQL, {
    ...options,
    variables: input,
  });
}
