/* eslint-disable no-restricted-imports */

import { gql, useQuery } from '@apollo/client';
import { RTElementProp, RTImageElement } from '@rmvw/x-common';
import { Image } from 'antd';
import * as React from 'react';
import { useSelected } from 'slate-react';
import styled from 'styled-components';

import { IRTElementProps } from '../../IRTElementProps';
import ElementPlaceholder from '../common/ElementPlaceholder';
import ResolverStatusOverlay from '../common/ResolverStatusOverlay';
import { useResolverStatus } from '../common/useResolverStatus';
import VoidInlineElement from '../common/VoidInlineElement';

import {
  BATCHED__ImageElement_ImageQuery,
  BATCHED__ImageElement_ImageQueryVariables,
} from './___generated___/ImageElement.types';

const DEFAULT_IMAGE_HEIGHT = 135;
const DEFAULT_IMAGE_WIDTH = 240;

const fragment = gql`
  fragment CF_ImageElement on Image {
    id
    previewUrl: url(size: { height: 270, width: 480 })
    galleryUrl: url(size: { height: 4096, width: 4096 })
  }
`;

const ImageQuery = gql`
  ${fragment}
  # Batched query
  query BATCHED__ImageElement_ImageQuery($imageId: ID!) {
    image(id: $imageId) {
      ...CF_ImageElement
    }
  }
`;

const _Container = styled.div`
  display: inline-block;
  vertical-align: bottom;
`;

const _InnerContainer = styled.div<{ $selected: boolean }>`
  filter: ${(props) => (props.$selected ? 'drop-shadow(0 0 4px blue)' : undefined)};
  padding: 4px;
  position: relative;
`;

const _Image = styled(Image)<{ $height?: number; $width?: number }>`
  height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
  max-height: ${DEFAULT_IMAGE_HEIGHT}px;
  max-width: ${DEFAULT_IMAGE_WIDTH}px;
  width: ${({ $width }) => ($width ? `${$width}px` : 'auto')};
`;

const _ImagePlaceholder = styled(ElementPlaceholder)<{ $height?: number; $width?: number }>`
  border-radius: 0;
  height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
  width: ${({ $width }) => ($width ? `${$width}px` : 'auto')};
`;

export default function ImageElement(props: IRTElementProps<RTImageElement>) {
  const selected = useSelected();

  const resolverStatus = useResolverStatus(props);

  const imageQuery = useQuery<BATCHED__ImageElement_ImageQuery, BATCHED__ImageElement_ImageQueryVariables>(ImageQuery, {
    variables: { imageId: props.element[RTElementProp.IMAGE__IMAGE_ID] ?? '' },
    skip: !props.element[RTElementProp.IMAGE__IMAGE_ID],
  });

  const height = props.element[RTElementProp.IMAGE__HEIGHT];
  const width = props.element[RTElementProp.IMAGE__WIDTH];

  const src = resolverStatus.previewUrl ?? imageQuery.data?.image?.previewUrl ?? undefined;

  return (
    <VoidInlineElement attributes={props.attributes} slateChildren={props.children}>
      <_Container>
        <_InnerContainer $selected={selected}>
          {src ? (
            // TODO: migrate off antd Image / PreviewImage
            <_Image
              $height={height ? +height : undefined}
              $width={width ? +width : undefined}
              preview={
                !props.editable
                  ? {
                      maskStyle: { backgroundColor: 'black', webkitAppRegion: 'no-drag' },
                      src: imageQuery.data?.image?.galleryUrl ?? undefined,
                    }
                  : false
              }
              src={src}
            />
          ) : (
            <_ImagePlaceholder
              $height={height ? +height : DEFAULT_IMAGE_HEIGHT}
              $width={width ? +width : DEFAULT_IMAGE_HEIGHT}
            />
          )}
          {props.editable && resolverStatus && <ResolverStatusOverlay status={resolverStatus.status} />}
        </_InnerContainer>
      </_Container>
    </VoidInlineElement>
  );
}

ImageElement.fragment = fragment;
