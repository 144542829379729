import * as React from 'react';
import styled from 'styled-components';

export interface IIconProps {
  className?: string;
  color?: string;
  title?: string;
  children?: JSX.Element | JSX.Element[];
  size?: string;
  strokeWidth?: string;
  role?: string;
  viewbox?: string;
}

const _Svg = styled.svg`
  color: ${({ color }) => color};
  fill: none;
  stroke-width: ${(props) => props.strokeWidth};
  vertical-align: baseline;

  > * {
    stroke: ${({ color }) => color};
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`;

export default function Icon({
  color = 'currentColor',
  size = '1em',
  strokeWidth = '1',
  role = 'presentation',
  viewbox = '0 0 12 12',
  ...props
}: IIconProps) {
  return (
    <_Svg
      viewBox={viewbox}
      className={props.className}
      color={color}
      aria-label={props.title}
      role={role}
      width={size}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{props.title}</title>
      {props.children}
    </_Svg>
  );
}
