import * as React from 'react';

import Icon, { IIconProps } from '../Icon';

export default function GoogleIcon(props: IIconProps) {
  return (
    <Icon {...props}>
      <path
        stroke="none"
        strokeWidth="0"
        fill="currentColor"
        d="M6.09694 6.95V5.09H10.8724C10.9439 5.405 11 5.7 11 6.115C11 8.97 9.04592 11 6.10204 11C3.28571 11 1 8.76 1 6C1 3.24 3.28571 1 6.10204 1C7.47959 1 8.63265 1.495 9.51531 2.305L8.06633 3.685C7.69898 3.345 7.05612 2.945 6.10204 2.945C4.41327 2.945 3.03571 4.32 3.03571 6.005C3.03571 7.69 4.41327 9.065 6.10204 9.065C8.05612 9.065 8.77551 7.74 8.90816 6.955H6.09694V6.95Z"
      />
    </Icon>
  );
}
