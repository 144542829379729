import * as React from 'react';
import styled from 'styled-components';

import { hexColorWithAlpha } from '../../../../lib/css';
import { IResolverStatus } from '../../../../lib/rich-text/Editor';
import LegacyPalette from '../../../../providers/ThemeProvider/color-palettes/LegacyPalette';
import { AlertTriangleIcon } from '../../../Icon';

const _ErrorContainer = styled.div`
  align-items: center;
  background-color: ${hexColorWithAlpha(LegacyPalette.red10, 0.9)};
  display: flex;
  flex-direction: column;
  gap: 4px;
  inset: 0;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  user-select: none;
`;

const _ErrorMessage = styled.div`
  color: ${({ theme }) => theme.color.color};
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  text-align: center;
`;

function _ResolverErrorOverlay({ error }: { error: Error }) {
  return (
    <_ErrorContainer>
      <AlertTriangleIcon size="small" />
      <_ErrorMessage>{error.message}</_ErrorMessage>
    </_ErrorContainer>
  );
}

const _ProgressContainer = styled.div<{ $opacity: number }>`
  bottom: 0;
  left: 0;
  opacity: ${({ $opacity }) => $opacity};
  overflow: hidden;
  position: absolute;
  right: 0;
  transition: opacity 0.25s ease-in-out;
`;

const _ProgressBar = styled.div<{ $widthPct: number }>`
  background-color: ${({ theme }) => theme.color.variant.primary.color};
  height: 2px;
  transition: width 0.25s ease-in-out;
  width: ${({ $widthPct }) => $widthPct}%;
`;

const DEFAULT_PROGRESS_BAR_OPACITY = 0.6;

export interface IResolverProgressOverlayProps {
  status?: IResolverStatus;
}

export function ResolverProgressOverlay({ status }: IResolverProgressOverlayProps) {
  const [progress, setProgress] = React.useState(status?.getProgress());
  const [opacity, setOpacity] = React.useState(DEFAULT_PROGRESS_BAR_OPACITY);

  React.useEffect(() => {
    const interval = setInterval(() => setProgress(status?.getProgress()), 250);
    return () => clearInterval(interval);
  }, [status]);

  React.useEffect(() => {
    if (progress !== 1) {
      return;
    }
    const hideProgress = setTimeout(() => setOpacity(0), 500);
    return () => clearTimeout(hideProgress);
  }, [progress]);

  if (progress === undefined || status?.error) {
    return null;
  }

  return (
    <_ProgressContainer $opacity={opacity}>
      <_ProgressBar $widthPct={Math.round(progress * 100)} />
    </_ProgressContainer>
  );
}

export interface IResolverStatusOverlayProps {
  status?: IResolverStatus;
}

export default function ResolverStatusOverlay({ status }: IResolverStatusOverlayProps) {
  if (!status) {
    return null;
  }

  return status.error ? <_ResolverErrorOverlay error={status.error} /> : <ResolverProgressOverlay status={status} />;
}
