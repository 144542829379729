import { default as PossibleTypesResult, PossibleTypesResultData } from '../___generated___/globalTypes';

type GQLImplementationTypes<I extends keyof PossibleTypesResultData['possibleTypes']> =
  PossibleTypesResultData['possibleTypes'][I][number];
export const GQLImplementationTypes = PossibleTypesResult.possibleTypes;

export type ThreadType = GQLImplementationTypes<'IThread'>;
export type TaggableType = GQLImplementationTypes<'ITaggable'>;

function implementsGQLInterface<T extends string>(values: T[]) {
  return (obj: { __typename: string }): obj is { __typename: T } => !!values.find((t) => t === obj.__typename);
}

export const isITaggable = implementsGQLInterface(GQLImplementationTypes.ITaggable);
export const isIThread = implementsGQLInterface(GQLImplementationTypes.IThread);
