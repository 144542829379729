import * as React from 'react';
import styled from 'styled-components';

export type IShimmerProps = React.HTMLAttributes<HTMLDivElement>;

const Shimmer = styled.div<{ $duration?: string }>`
  animation: shimmer ${({ $duration }) => $duration ?? '2s'} infinite linear;
  background-color: ${({ theme }) => theme.color.background400};
  border-radius: 8px;

  /* TODO: investigate whether bootstrap's Placeholder is better */
  mask: linear-gradient(-45deg, #000 30%, #0005 50%, #000 70%) right/400% 100%;
  user-select: none;

  @keyframes shimmer {
    100% {
      mask-position: left;
    }
  }
`;

export default Shimmer;
