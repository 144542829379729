import { gql, useMutation } from '@apollo/client';
import * as React from 'react';

import Logger from '../../lib/observability/Logger';

import { HM_useFollowControl, HM_useFollowControlVariables } from './___generated___/useFollowControl.types';

export const FollowControlQuery = gql`
  # Batched query
  query BATCHED__HQ_useFollowControl($threadId: ID!) {
    thread(id: $threadId) {
      id
      __typename
      following
    }
  }
`;

const followMutationGQL = gql`
  mutation HM_useFollowControl($input: FollowThreadInput) {
    followThread(input: $input) {
      id
      following
    }
  }
`;

export default function useFollowControl(threadId: string) {
  const [followMutation, followMutationResult] = useMutation<HM_useFollowControl, HM_useFollowControlVariables>(
    followMutationGQL
  );

  const setFollowing = React.useCallback(
    (following: boolean) => {
      followMutation({ variables: { input: { threadId, following } } }).catch((err) =>
        Logger.error(err, '[useFollowControl] Mutation failed')
      );
    },
    [threadId, followMutation]
  );

  return React.useMemo(
    () => ({
      loading: followMutationResult.loading,
      setFollowing,
    }),
    [followMutationResult.loading, setFollowing]
  );
}
