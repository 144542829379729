import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import Spinner from '../Spinner/Spinner';

export const DEFAULT_AVATAR_SIZE = 40;

const _Avatar = styled.div<IAvatarProps>`
  align-items: center;
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.color.background400};
  border-radius: ${({ shape, theme }) => (shape === 'square' ? theme.borderRadius.small : '50%')};
  color: ${({ color, theme }) => color || theme.color.color};
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;

  ${({ size }) => {
    return `
      aspect-ratio: 1;
      flex-shrink: 0;
      height: ${size ? `${size}px` : 'auto'};
      width: ${size ? `${size}px` : '100%'};
    `;
  }}

  /**
   * Add a subtle, translucent border to give the image some definition if it
   * bleeds into the background.
   */
  &::after {
    border-radius: inherit;
    content: '';
    inset: 0;
    position: absolute;
  }
`;

const _Image = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export function getInitials(alt?: string) {
  return alt
    ?.replace(/\(.+\)/, '') // remove anything in parentheses
    .split(/[#\s]/) // split into words
    .map((w) => w[0]) // extract first letter from each word
    .join('') // re-join first letters
    .substring(0, 3) // trim to max length 3
    .toUpperCase(); // make uppercase
}

export type AvatarShape = 'circle' | 'square';

export interface IAvatarProps extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> {
  backgroundColor?: string;
  color?: string;
  isLoading?: boolean;
  shape?: AvatarShape;
  size?: number; // TODO: need to support relative sizes
  src?: React.ReactNode;
}

/**
 * Basic `Avatar` component that wraps 3rd-party version so we can control
 * styling and behavior.
 */
export function Avatar({ alt, isLoading, src, ...props }: IAvatarProps) {
  // size is not always provided, so we need to use the resize observer to calculate `fontSize`
  const { ref, width: size } = useResizeObserver();
  const theme = useTheme();

  if (isLoading) {
    return (
      <_Avatar {...props} color={theme.color.color}>
        <Spinner fullScreen size="xxSmall" />
      </_Avatar>
    );
  }

  if (typeof src === 'string') {
    return (
      <_Avatar {...props}>
        <_Image alt={alt} src={src} />
      </_Avatar>
    );
  }

  // If there is no image source, use alt initials instead
  const initials = getInitials(alt);
  const fontSize = size && Math.round((0.9 * size) / Math.max(2, initials?.length ?? 0));

  return (
    <_Avatar {...props} ref={ref} style={{ ...props.style, fontSize, lineHeight: size && `${size}px` }}>
      {src || initials}
    </_Avatar>
  );
}
