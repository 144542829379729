import * as React from 'react';
import RBModalContext from 'react-bootstrap/ModalContext';
import RBModalHeader, { ModalHeaderProps } from 'react-bootstrap/ModalHeader';
import styled from 'styled-components';

import CloseButton from '../CloseButton';

const _Header = styled(RBModalHeader)`
  border-color: ${({ theme }) => theme.color.border};
  display: flex;
  gap: 8px;
  padding: 12px 16px;
`;

const _HeaderChildren = styled.div`
  flex: 1;
  overflow: hidden;
`;

const _CloseButton = styled(CloseButton)`
  margin: -4px;
`;

/**
 * Custom `ModalHeader` using our own `CloseButton`.
 */
const ModalHeader = React.forwardRef<HTMLDivElement, ModalHeaderProps>(
  ({ children, closeButton, closeLabel, onHide, ...props }, ref) => {
    const context = React.useContext(RBModalContext);

    const onClick = () => {
      context?.onHide();
      onHide?.();
    };

    return (
      <_Header {...props} ref={ref}>
        <_HeaderChildren>{children}</_HeaderChildren>
        {closeButton && <_CloseButton aria-label={closeLabel} onClick={onClick} />}
      </_Header>
    );
  }
);

ModalHeader.displayName = 'ModalHeader';

export default ModalHeader;
