import {
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  FetchRequestor,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest,
} from '@openid/appauth';
import IdTokenVerifier from 'idtoken-verifier';
import * as React from 'react';

export interface IUseTokenRequestArgs {
  authConfig: AuthorizationServiceConfiguration;
  clientId: string;
  issuer: string;
  redirectUri: string;
}

interface ITokenRequest {
  grantType: typeof GRANT_TYPE_AUTHORIZATION_CODE | typeof GRANT_TYPE_REFRESH_TOKEN;
}

interface ITokenRequestForAuthCode extends ITokenRequest {
  code: string;
  codeVerifier?: string;
  grantType: typeof GRANT_TYPE_AUTHORIZATION_CODE;
}

interface ITokenRequestForRefreshToken extends ITokenRequest {
  grantType: typeof GRANT_TYPE_REFRESH_TOKEN;
  refreshToken: string;
}

export type ITokenRequestArgs = ITokenRequestForAuthCode | ITokenRequestForRefreshToken;

export default function useTokenRequest({ authConfig, clientId, issuer, redirectUri }: IUseTokenRequestArgs) {
  const tokenRequest = React.useCallback(
    async (args: ITokenRequestArgs) => {
      const response = await new BaseTokenRequestHandler(new FetchRequestor()).performTokenRequest(
        authConfig,
        new TokenRequest({
          client_id: clientId,
          redirect_uri: redirectUri,
          grant_type: args.grantType,
          code: args.grantType === GRANT_TYPE_AUTHORIZATION_CODE ? args.code : undefined,
          extras:
            args.grantType === GRANT_TYPE_AUTHORIZATION_CODE && args.codeVerifier
              ? { code_verifier: args.codeVerifier }
              : {},
          refresh_token: args.grantType === GRANT_TYPE_REFRESH_TOKEN ? args.refreshToken : undefined,
        })
      );

      if (!response.accessToken || !response.refreshToken || !response.idToken) {
        throw new Error('Incomplete token response');
      }

      // Extract accountId details from id token
      const verifier = new IdTokenVerifier({ audience: clientId, issuer });
      const tokenPayload = verifier.decode(response.idToken).payload;
      const accountId: string | undefined = tokenPayload.sub;
      if (!accountId) {
        throw new Error('[useTokenRequest] No `sub` claim found in idToken');
      }

      return { accountId, accessToken: response.accessToken, refreshToken: response.refreshToken };
    },
    [authConfig, clientId, issuer, redirectUri]
  );

  return { tokenRequest };
}
