/**
 * The Courbet color palette.
 */
const CourbetPalette = {
  gray1000: '#0E0E25',
  gray975: '#191929',
  gray950: '#1D1D30',
  gray900: '#24243B',
  gray800: '#2F2F48',
  gray750: '#3E3E5C',
  gray700: '#46465D',
  gray600: '#5D5D71',
  gray500: '#747485',
  gray400: '#8C8C9A',
  gray300: '#A3A3AE',
  gray200: '#BABAC2',
  gray100: '#D1D1D6',
  gray50: '#E8E8EB',
  gray10: '#F5F5F9',
  gray0: '#FFFFFF',

  mint900: '#002600',
  mint800: '#015919',
  mint700: '#348C4C',
  mint600: '#67BF7F',
  mint500: '#9AF2B2',
  mint400: '#AEF5C1',
  mint300: '#C2F7D1',
  mint200: '#D7FAE0',
  mint100: '#EBFCF0',

  orange700: '#F18E2B',
  orange100: '#f4ebe3',

  purple900: '#1D0A41',
  purple800: '#281057',
  purple700: '#5A3999',
  purple750: '#75649C',
  purple600: '#8661CD',
  purple500: '#C09FFF',
  purple400: '#CCB2FF',
  purple300: '#D9C5FF',
  purple200: '#E6D9FF',
  purple100: '#F3ECFF',

  red700: '#BD2C2C',
  red600: '#C94545',
  red500: '#F95959',
  red100: '#FEDEDE',

  teal900: '#000D0D',
  teal800: '#004040',
  teal700: '#0C7373',
  teal600: '#3FA6A6',
  teal500: '#72D9D9',
  teal400: '#8FE1E1',
  teal300: '#ABE8E8',
  teal200: '#C7F0F0',
  teal100: '#E3F7F7',

  yellow700: '#A28300',
  yellow100: '#F9F4D0',
};

export default CourbetPalette;
