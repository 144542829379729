import { gql, useMutation } from '@apollo/client';
import * as React from 'react';

import { HM_editResourceLabel, HM_editResourceLabelVariables } from './___generated___/useEditResourceLabel.types';

const mutationGQL = gql`
  mutation HM_editResourceLabel($input: EditResourceLabelInput!) {
    editResourceLabel(input: $input) {
      id
      label
    }
  }
`;

export default function useEditResourceLabel(threadResourceId: string) {
  const [editResourceLabel, editResourceLabelResult] = useMutation<HM_editResourceLabel, HM_editResourceLabelVariables>(
    mutationGQL
  );
  return React.useCallback(
    async (label: string) => {
      await editResourceLabel({ variables: { input: { threadResourceId, label } } });
    },
    [editResourceLabel, threadResourceId]
  );
}
