import * as React from 'react';
import RBModal, { ModalProps } from 'react-bootstrap/Modal';
import styled from 'styled-components';

import { truncateText } from '../../lib/css';

import ModalHeader from './ModalHeader';

export type ModalSize = ModalProps['size'];
export type { ModalProps };

const _Modal = styled(RBModal)`
  /* Default Bootstrap z-index is 1055, but that conflicts with AntD flyouts. */
  z-index: 1050;
`;

const _Dialog = styled(RBModal.Dialog)`
  /* There's no 'Modal.Content' so we need to target the class name. */
  .modal-content {
    background-color: ${({ theme }) => theme.color.background800};
    border: 1px solid ${({ theme }) => theme.color.secondaryBorder};
    border-radius: ${({ theme }) => theme.borderRadius.medium};
  }
`;

const _Title = styled(RBModal.Title)`
  font-size: ${({ theme }) => theme.fontSize.h5};
  line-height: 1.2;

  ${truncateText()}
`;

const _Body = styled(RBModal.Body)`
  padding: 16px;
`;

const _Footer = styled(RBModal.Footer)`
  border-color: ${({ theme }) => theme.color.border};
  gap: 8px;
  padding: 12px 16px;

  /* Override Bootstrap styles. */
  & > * {
    margin: 0;
  }
`;

function Modal({ centered = true, dialogAs = _Dialog, ...props }: ModalProps) {
  return <_Modal {...props} centered={centered} dialogAs={dialogAs} />;
}

Modal.Body = _Body;
Modal.Dialog = _Dialog;
Modal.Footer = _Footer;
Modal.Header = ModalHeader;
Modal.Title = _Title;

export default Modal;
