import * as React from 'react';

/**
 * Example Usage:
 * <ConditionalWrapper condition={shouldBold} wrapper={children => <strong>{children}</strong>} />
 *
 * Citation: https://gist.github.com/kitze/23d82bb9eb0baabfd03a6a720b1d637f?permalink_comment_id=3527159#gistcomment-3527159
 */
interface IProps {
  condition: boolean;
  wrapper: React.FC;
}

export const ConditionalWrapper: React.FC<React.PropsWithChildren<IProps>> = ({ condition, wrapper, children }) =>
  condition ? wrapper({ children }) : <>{children}</>;
