import { MarkGithubIcon } from '@primer/octicons-react';
import { exhaustive } from '@rmvw/x-common';
import * as React from 'react';
import styled from 'styled-components';

import { Application } from '../../../___generated___/globalTypes';
import Logger from '../../../lib/observability/Logger';
import { ProfileAvatar } from '../../Avatar';
import FileUploadIcon from '../../FileUploadIcon';
import { AlertTriangleIcon, CameraIcon, ImageIcon, LinkIcon, VideoIcon } from '../../Icon';

import { CF_ThreadResourceGalleryItem } from './___generated___/ThreadResourceGalleryItem.types';
import { CF_ThreadResourceListItem } from './___generated___/ThreadResourceListItem.types';

const _ThumbnailImage = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

export interface IThreadResourceThumbnailProps {
  resource: CF_ThreadResourceGalleryItem['resource'] | CF_ThreadResourceListItem['resource'];
  size: number;
}

export default function ThreadResourceThumbnail({ resource, size }: IThreadResourceThumbnailProps) {
  switch (resource?.__typename) {
    case 'Discussion':
      return <ProfileAvatar profile={resource} iconSize={size} />;

    case 'File':
      return (
        <FileUploadIcon
          extension={resource.name.split('.').pop()}
          contentType={resource.contentType ?? undefined}
          size={size}
        />
      );

    case 'Link':
      if (resource.appObject?.app) {
        switch (resource.appObject.app) {
          case Application.GITHUB:
            return <MarkGithubIcon size={size} />;
          default:
            // Warn so we don't forget to update this code path when adding new appObject.app values
            Logger.warn(`[ThreadResourceThumbnail] Unknown appObject.app ${resource.appObject.app}`);
            return <AlertTriangleIcon size={size} />;
        }
      }
      return resource.image?.url ? <_ThumbnailImage src={resource.image.url} /> : <LinkIcon size={size} />;

    case 'Image':
      return resource.url ? <_ThumbnailImage src={resource.url} /> : <ImageIcon size={size} />;

    case 'Media':
      return resource.previewImage?.url ? (
        <_ThumbnailImage src={resource.previewImage.url} />
      ) : (
        <VideoIcon size={size} />
      );

    case 'Meeting':
      return resource.media?.previewImage?.url ? (
        <_ThumbnailImage src={resource.media.previewImage.url} />
      ) : (
        <CameraIcon size={size} />
      );

    case 'Team':
      return <ProfileAvatar profile={resource} size={size} />;

    case undefined:
      return <AlertTriangleIcon size={size} />; // TODO better icon

    default:
      exhaustive(resource, `Unhandled resource type ${(resource as any)?.__typename}`);
  }
}
