import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { ExpandIcon } from '../../Icon';
import PaneHeader from '../../PaneHeader';
import { ButtonSize } from '../../ui/Button';

interface IProps {
  onClick?: () => void;
  path?: string;
  size?: ButtonSize;
}

export default function MaximizeThreadControl(props: IProps) {
  const navigate = useNavigate();
  return (
    <PaneHeader.ControlButton
      icon={<ExpandIcon />}
      onClick={() => (props?.onClick ? props.onClick() : props.path ? navigate(props.path) : undefined)}
      label={'Expand'}
      size={props.size}
    />
  );
}
