import * as React from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import BaseReactPlayer, { OnProgressProps } from 'react-player/base';

import Logger from '../../lib/observability/Logger';

// Load global HLS dependency if it hasn't already been loaded. This is to
// prevent ReactPlayer from dynamically loading this dependency over the wire,
// which we do not want for deterministic dependency management + security
// policy simplicity.
if (!(window as any).Hls) {
  (window as any).Hls = require('hls.js');
}

type RemoveIndex<T> = {
  [P in keyof T as string extends P ? never : number extends P ? never : P]: T[P];
};

export type { OnProgressProps };

export type IMediaPlayerProps = RemoveIndex<ReactPlayerProps>;
export type IMediaPlayerRef = BaseReactPlayer<ReactPlayerProps>;

const MediaPlayer = React.forwardRef<IMediaPlayerRef, IMediaPlayerProps>(
  ({ config, onError, ...props }: IMediaPlayerProps, ref: React.ForwardedRef<IMediaPlayerRef>) => {
    return (
      <ReactPlayer
        {...props}
        config={{
          // Default config for HLS streams
          ...config,
          file: {
            ...config?.file,
            hlsOptions: {
              capLevelToPlayerSize: true, // Automatically adjust quality based on player size
              debug: false, // << set true to enable HLS.js debug logging
              manifestLoadingMaxRetry: 15,
              manifestLoadingMaxRetryTimeout: 15000,
              manifestLoadingRetryDelay: 1000,
              manifestLoadingTimeOut: 30000,
              maxLoadingDelay: 2,
              startLevel: 2, // Optimistically start with highest possible playback quality
              ...config?.file?.hlsOptions,
            },
          },
        }}
        data-chromatic="ignore"
        onError={(error: any, data?: any, hlsInstance?: any) => {
          // Default error handler for HLS streams
          if (onError) {
            return onError(error, data, hlsInstance);
          }

          // Attempt to recover from fatal errors
          // @see https://github.com/video-dev/hls.js/blob/master/docs/API.md#fatal-error-recovery
          if (data?.fatal) {
            switch (data?.type) {
              case 'networkError':
                Logger.warn(error, '[MediaPlayer] Attempting to recover from networkError');
                hlsInstance?.startLoad();
                break;

              case 'mediaError':
                Logger.warn(error, '[MediaPlayer] Attempting to recover from mediaError');
                hlsInstance?.recoverMediaError();
                break;

              default:
                Logger.error(error, '[MediaPlayer] Cannot recover from HLS error');
                hlsInstance?.destroy();
                break;
            }
          }
        }}
        ref={ref}
      />
    );
  }
);

MediaPlayer.displayName = 'MediaPlayer';

export default MediaPlayer;
