/* eslint-disable no-console */

import * as Sentry from '@sentry/react';

const IS_DEV = process.env['NODE_ENV'] !== 'production';

export default class Logger {
  private static _log(level: Sentry.SeverityLevel, msgOrObj: string | object, msg?: string): void {
    // Send warnings, errors, and fatal messages to Sentry in production
    if (!IS_DEV && ['warn', 'error', 'fatal'].includes(level)) {
      if (typeof msgOrObj !== 'string') {
        Sentry.captureMessage(msg as string, {
          level,
          extra: {
            error: msgOrObj instanceof Error ? msgOrObj : undefined,
            data: JSON.stringify(msgOrObj),
          },
        });
      } else {
        Sentry.captureMessage(msgOrObj, { level });
      }
    }

    switch (level) {
      case 'fatal':
        console.error.apply(null, ['>>> FATAL <<<', msg, msgOrObj].filter(Boolean));
        break;

      case 'error':
        console.error.apply(null, ['[ERROR]', msg, msgOrObj].filter(Boolean));
        break;

      case 'warning':
        console.warn.apply(null, ['[WARN]', msg, msgOrObj].filter(Boolean));
        break;

      case 'log':
      case 'info':
        console.log.apply(null, ['[INFO]', msg, msgOrObj].filter(Boolean));
        break;

      case 'debug':
        if (IS_DEV) {
          console.log.apply(null, ['[DEBUG]', msg, msgOrObj].filter(Boolean));
        }
        break;
    }
  }

  static fatal(obj: object, msg: string): void;
  static fatal(msg: string): void;
  static fatal(msgOrObj: string | object, msg?: string): void {
    this._log('fatal', msgOrObj, msg);
  }

  static error(obj: object, msg: string): void;
  static error(msg: string): void;
  static error(msgOrObj: string | object, msg?: string): void {
    this._log('error', msgOrObj, msg);
  }

  static warn(obj: object, msg: string): void;
  static warn(msg: string): void;
  static warn(msgOrObj: string | object, msg?: string): void {
    this._log('warning', msgOrObj, msg);
  }

  static info(obj: object, msg: string): void;
  static info(msg: string): void;
  static info(msgOrObj: string | object, msg?: string): void {
    this._log('info', msgOrObj, msg);
  }

  static debug(obj: object, msg: string): void;
  static debug(msg: string): void;
  static debug(msgOrObj: string | object, msg?: string): void {
    this._log('debug', msgOrObj, msg);
  }
}
