import * as React from 'react';
import { ToastHeaderProps as RBToastHeaderProps } from 'react-bootstrap/ToastHeader';
import styled from 'styled-components';

import CloseButton from '../CloseButton';

import ToastContext from './ToastContext';

const _ToastHeader = styled.div`
  background-color: transparent;
  border-color: inherit;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  color: unset;
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &:last-child {
    border: 0;
    border-radius: inherit;
  }
`;

const _ToastHeaderChildren = styled.div`
  flex: 1;
`;

const _CloseButton = styled(CloseButton)`
  color: inherit;
`;

export type ToastHeaderProps = Omit<RBToastHeaderProps, 'closeVariant' | 'bsPrefix'>;

/**
 * Custom `ToastHeader` using our own `CloseButton`.
 */
const ToastHeader = React.forwardRef<HTMLDivElement, ToastHeaderProps>(
  ({ closeLabel = 'Close', closeButton = true, children, ...props }: ToastHeaderProps, ref) => {
    const { onClose } = React.useContext(ToastContext);

    function onClick(e: React.MouseEvent<HTMLButtonElement>) {
      onClose?.(e);
    }

    const className = ['toast-header', props.className].join(' ');

    return (
      <_ToastHeader {...props} className={className} ref={ref}>
        <_ToastHeaderChildren>{children}</_ToastHeaderChildren>
        {closeButton && <_CloseButton aria-label={closeLabel} onClick={onClick} data-dismiss="toast" size="small" />}
      </_ToastHeader>
    );
  }
);
ToastHeader.displayName = 'ToastHeader';

export default ToastHeader;
