import * as React from 'react';
import styled from 'styled-components';

import Card from '../Card';
import MetadataBadge from '../MetadataBadge';

export interface IThreadBadgeFooterProps {
  messageCount?: number;
  participantCount?: number;
  unreadMessageCount?: number;
  videoCount?: number;
  targetUrl?: string;
}

function ThreadBadgeFooter({
  messageCount,
  participantCount,
  targetUrl,
  unreadMessageCount,
  videoCount,
}: IThreadBadgeFooterProps) {
  const metadata = [
    {
      count: unreadMessageCount ? `${messageCount} (${unreadMessageCount} unread)` : messageCount,
      type: 'replies' as const,
      variant: unreadMessageCount ? ('callToAction' as const) : undefined,
    },
    { count: participantCount, type: 'participants' as const },
    { count: videoCount, type: 'videos' as const },
  ];

  return (
    <_Footer>
      <_MetaBadgeContainer>
        {metadata.map(
          ({ count, type, variant }) =>
            !!count && <MetadataBadge count={count} key={type} to={targetUrl} type={type} variant={variant} />
        )}
      </_MetaBadgeContainer>
    </_Footer>
  );
}

const _MetaBadgeContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const _Footer = styled(Card.Footer)`
  align-items: flex-start;
  flex-direction: column;
`;

export default ThreadBadgeFooter;
