import { format, isSameDay, isSameYear } from 'date-fns';
import * as React from 'react';
import styled from 'styled-components';

export enum TimestampFormat {
  SHORT = 'short',
  TIME = 'time',
}

export function getTimestampFormat(timestamp: Date, formatModifier?: TimestampFormat) {
  if (isSameDay(timestamp, new Date()) || formatModifier === TimestampFormat.TIME) {
    return 'h:mm aaa';
  }

  if (isSameYear(timestamp, new Date())) {
    return formatModifier === TimestampFormat.SHORT ? 'MMM d' : 'MMM d, h:mm aaa';
  }

  return formatModifier === TimestampFormat.SHORT ? 'MMM d, yyyy' : 'MMM d, yyyy, h:mm aaa';
}

export interface ITimestampProps extends React.HTMLAttributes<HTMLSpanElement> {
  format?: TimestampFormat;
  timestamp: Date;
}

export default function Timestamp({ timestamp, ...props }: ITimestampProps) {
  return <TimestampDiv {...props}>{format(timestamp, getTimestampFormat(timestamp, props.format))}</TimestampDiv>;
}

export const TimestampDiv = styled.div`
  color: ${({ theme }) => theme.color.secondaryColor};
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
`;
