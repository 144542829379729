import * as React from 'react';

import Spinner from '../Spinner';

import type { IBaseRichTextProps } from './BaseRichText';

const BaseRichText = React.lazy(() => import('./BaseRichText'));

/**
 * RichText renderer. There are enough crazy dependencies in the rich text editor
 * that we need to lazy load it.
 */
export default function RichText(props: IBaseRichTextProps) {
  return (
    <React.Suspense fallback={<Spinner fullScreen />}>
      <BaseRichText {...props} />
    </React.Suspense>
  );
}
