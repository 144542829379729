import * as React from 'react';
import RBSpinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';

import { CanonicalSize, CanonicalSizePx } from '../../providers/ThemeProvider/themes';

export type SpinnerSize = 'default' | CanonicalSize | number;

function getSize(size?: SpinnerSize) {
  if (typeof size === 'number') {
    return size;
  }

  return !size || size === 'default' ? CanonicalSizePx.xSmall : CanonicalSizePx[size];
}

const _Container = styled.div<{ fullScreen?: boolean }>`
  display: inline-flex;

  ${({ fullScreen }) => {
    if (fullScreen) {
      return `
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
      `;
    }
  }};
`;

const _Spinner = styled(RBSpinner)<{ $size?: SpinnerSize }>`
  border-right-color: transparent;
  border-style: dotted;
  border-top-color: transparent;
  border-width: 1.5px;
  height: ${({ $size }) => getSize($size)}px;
  opacity: 0.66;
  width: ${({ $size }) => getSize($size)}px;
`;

export interface ISpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  fullScreen?: boolean;
  label?: string;
  size?: SpinnerSize;
}

function Spinner({ label = 'Loading', size, ...props }: ISpinnerProps) {
  return (
    <_Container {...props}>
      <_Spinner animation="border" aria-label={label} role="status" $size={size} />
    </_Container>
  );
}

export default Spinner;
