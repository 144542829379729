/**
 * Apply a numeric opacity to a hex value. Useful with theme colors.
 *
 * Example:
 *
 *   hexColorWithAlpha(theme.color.variant.primary.color, 0.5); // #0f9ab980
 */
export function hexColorWithAlpha(hexColor: string, alpha: number) {
  if (alpha < 0 || alpha > 1) {
    throw Error('`alpha` must be a numeric value between 0 and 1.');
  }

  // Validate that the color string is a hex value.
  if (!/^#[0-9A-F]{6}$/i.test(hexColor)) {
    throw Error('`hexColor` must be a valid hexadecimal (#rrggbb) color value.');
  }

  const aa = Math.round(alpha * 255)
    .toString(16)
    .padStart(2, '0');

  return `${hexColor}${aa}`;
}

/**
 * Adjust the brightness of a hex color value.
 */
export function hexColorAdjustBrightness(hexColor: string, multiplier: number) {
  if (multiplier < 0) {
    throw Error('`multiplier` must be a numeric value >= 0');
  }

  // Convert the hex color to an RGB array.
  const rgb = hexToRGB(hexColor);
  if (!rgb) {
    throw Error('Failed to parse hex color value');
  }

  // Adjust the RGB values.
  const adjustedRgb = rgb.map((x) => Math.round(Math.min(255, x * multiplier)));

  // Convert the adjusted RGB values back to a hex color string.
  return `#${adjustedRgb.map((x) => x.toString(16).padStart(2, '0')).join('')}`;
}

/**
 * Blend two hex color values together.
 */
export function hexColorBlend(hexColor1: string, hexColor2: string, ratio: number) {
  if (ratio < 0 || ratio > 1) {
    throw Error('`ratio` must be a numeric value between 0 and 1.');
  }

  // Convert the hex color to an RGB array.
  const rgb1 = hexToRGB(hexColor1);
  if (!rgb1) {
    throw Error('Failed to parse hex color value');
  }

  const rgb2 = hexToRGB(hexColor2);
  if (!rgb2) {
    throw Error('Failed to parse hex color value');
  }

  // Blend the RGB values.
  const blendedRgb = rgb1.map((x, i) => Math.round(x * (1 - ratio) + rgb2[i] * ratio));

  // Convert the blended RGB values back to a hex color string.
  return `#${blendedRgb.map((x) => x.toString(16).padStart(2, '0')).join('')}`;
}

export function hexToRelativeLuminance(hexColor: string) {
  // Convert the hex color to an RGB array.
  const rgb = hexToRGB(hexColor);
  if (!rgb) {
    throw Error('Failed to parse hex color value');
  }

  // Calculate the relative luminance.
  const [r, g, b] = rgb.map((c) => {
    const s = c / 255;
    return s <= 0.03928 ? s / 12.92 : ((s + 0.055) / 1.055) ** 2.4;
  });

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

export function hexToRGB(hexColor: string): [number, number, number] | undefined {
  // Validate that the color string is a hex value.
  if (!/^#[0-9A-F]{6}$/i.test(hexColor)) {
    return undefined;
  }

  // Convert the hex color to an RGB array.
  const rgb = hexColor
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16));

  if (rgb?.length !== 3) {
    return undefined;
  }

  return rgb as [number, number, number];
}

/**
 * CSS mixin for truncating multi-line text.
 *
 * References:
 * - https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
 * - https://caniuse.com/mdn-css_properties_-webkit-line-clamp
 */
export function lineClamp(lines: number) {
  if (!Number.isInteger(lines) || lines <= 0) {
    throw Error('The specified number of lines must be a positive integer');
  }

  if (lines === 1) {
    // eslint-disable-next-line
    console.warn('Use `truncateText` to truncate a single line of text.');
  }

  return `
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${lines};
    overflow: hidden;
  `;
}

/**
 * Mixin for truncating single-line text.
 */
export function truncateText() {
  return `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;
}

/**
 * Mixin for uppercase-ing text.
 */
export function uppercaseText() {
  return `
    letter-spacing: 1px;
    text-transform: uppercase;
  `;
}
