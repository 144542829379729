import { secondsToHours, secondsToMinutes } from 'date-fns';
import * as React from 'react';
import styled from 'styled-components';

const _Container = styled.div`
  display: inline-flex;
`;

export interface IDurationProps extends React.HTMLAttributes<HTMLDivElement> {
  seconds: number;
}

function Duration({ seconds, ...props }: IDurationProps) {
  const normalizedSeconds = Math.max(0, Math.floor(seconds));
  const hrs = secondsToHours(normalizedSeconds);
  const min = secondsToMinutes(normalizedSeconds) % 60;
  const sec = Math.floor(normalizedSeconds) % 60;

  const elements: string[] = [];
  if (hrs > 0) {
    elements.push(hrs.toString());
    elements.push(min.toString().padStart(2, '0')); // Always pad minutes if hours are present
  } else {
    elements.push(min.toString());
  }
  elements.push(sec.toString().padStart(2, '0'));
  return <_Container {...props}>{elements.join(':')}</_Container>;
}

export default Duration;
