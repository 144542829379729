import * as React from 'react';
import styled from 'styled-components';

import HeaderSearch from '../Header/HeaderSearch';
import Modal, { ModalProps } from '../Modal';

const _ModalDialog = styled(Modal.Dialog)`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 220px;
  min-height: 0;

  & > .modal-content {
    background-color: transparent;
    border: none;
    border-radius: unset;
    width: 650px;
  }
`;

const _HeaderSearch = styled(HeaderSearch)`
  width: 650px;
`;

export interface ISearchModalProps extends ModalProps {}

function SearchModal(props: ISearchModalProps) {
  return (
    <Modal {...props} animation={false} dialogAs={_ModalDialog}>
      <_HeaderSearch autoFocus limit={10} onExecute={() => props.onHide?.()} size="lg" />
    </Modal>
  );
}

export default SearchModal;
