import { AuthorizationServiceConfiguration } from '@openid/appauth';
import * as React from 'react';

export interface IUseAuthorizationServiceConfigurationArgs {
  issuer: string;
}

export default function useAuthorizationServiceConfiguration({ issuer }: IUseAuthorizationServiceConfigurationArgs) {
  const authConfig = React.useMemo(
    () =>
      new AuthorizationServiceConfiguration({
        authorization_endpoint: `${issuer}/auth`,
        revocation_endpoint: `${issuer}/token/revocation`,
        token_endpoint: `${issuer}/token`,
      }),
    [issuer]
  );

  return { authConfig };
}
