import * as React from 'react';
import styled from 'styled-components';

import useOverflowObserver, { IOverflowObserverHookProps } from './useOverflowObserver';

const _Outer = styled.div`
  overflow: hidden;
`;

const _Inner = styled.div<{ $direction?: 'horizontal' | 'vertical' }>`
  display: block;
  height: ${({ $direction }) => (!$direction || $direction === 'vertical' ? 'fit-content' : undefined)};
  width: ${({ $direction }) => (!$direction || $direction === 'horizontal' ? 'fit-content' : undefined)};
`;

export interface IOverflowObserverProps extends IOverflowObserverHookProps, React.HTMLAttributes<HTMLDivElement> {
  direction?: 'horizontal' | 'vertical';
}

/**
 * OverflowObserver invokes the callback `onOverflowChange` to inform whether its
 * contents are overflowing, and by how much.
 */
function OverflowObserver({ direction, onOverflowChange, ...props }: React.PropsWithChildren<IOverflowObserverProps>) {
  const { innerRef, outerRef } = useOverflowObserver({ onOverflowChange });

  return (
    <_Outer {...props} ref={outerRef}>
      <_Inner $direction={direction} ref={innerRef}>
        {props.children}
      </_Inner>
    </_Outer>
  );
}

export default OverflowObserver;
