import { IS_DEV } from '@rmvw/x-common';
import * as React from 'react';
import styled from 'styled-components';

import { AlertTriangleIcon } from '../../components/Icon';
import Logger from '../../lib/observability/Logger';

const _Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  justify-content: center;
  padding: 24px;
`;

const _Message = styled.div`
  font-size: 150%;
`;

const _SubMessage = styled.div`
  text-align: center;
`;

const _DevModeErrorDetails = styled.pre`
  max-width: 100%; /* Forces the section to scale with the page width. */
`;

export default function ErrorScene({ error }: { error?: Error }) {
  // Log this error
  Logger.error(error ?? {}, `[ErrorScene] ${error?.message ?? 'Unknown error'}`);

  return (
    <_Container>
      <AlertTriangleIcon size="xLarge" />
      <_Message>Oops, something went wrong</_Message>
      <_SubMessage>
        We&lsquo;ve logged the error and will take a look soon
        <br />
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            window.location.reload();
          }}
        >
          Please click here to reload the app
        </a>
      </_SubMessage>
      {IS_DEV && error ? <_DevModeErrorDetails>{error.stack}</_DevModeErrorDetails> : null}
    </_Container>
  );
}
