import { ApolloProvider as _ApolloProvider } from '@apollo/client';
import { getApiApolloClient } from '@rmvw/c-common';
import { RMVWAppId } from '@rmvw/x-common';
import * as Sentry from '@sentry/react';
import * as React from 'react';

import Toast from '../components/Toast/Toast';
import { IS_DESKTOP_APP, IS_MOBILE_APP } from '../env';
import Logger from '../lib/observability/Logger';

import { useAuthManager } from './AuthProvider';

const SHOW_NETWORK_ERROR_DELAY_MS = 2000;

export default function ApolloProvider(props: React.PropsWithChildren<unknown>) {
  const { accessToken, logout, refreshAccessToken } = useAuthManager();
  const accessTokenRef = React.useRef<string | null>(accessToken);
  const [networkErrorCode, setNetworkErrorCode] = React.useState<number | null>(null);
  const [showNetworkError, setShowNetworkError] = React.useState<boolean>(false);

  /**
   * Synchronize the accessTokenRef with the accessToken from the AuthProvider.
   * We use a ref to prevent re-instantiation of ApolloClient as no re-renders
   * are necessary when an accessToken updates in this context.
   */
  React.useEffect(() => {
    accessTokenRef.current = accessToken;
  }, [accessToken]);

  const gqlClient = React.useMemo(() => {
    Logger.debug('Init Apollo client...');
    return getApiApolloClient({
      getAppInfo: async () => ({
        appId: IS_MOBILE_APP ? RMVWAppId.C_MOBILE : IS_DESKTOP_APP ? RMVWAppId.C_DESKTOP : RMVWAppId.C_WEBAPP,
        build: IS_MOBILE_APP
          ? (await window._mobileBridge?.getAppInfo?.())?.build ?? ''
          : IS_DESKTOP_APP
          ? (await window._desktopBridge?.getAppInfo?.())?.build ?? ''
          : process.env['GIT_VERSION'] ?? '',
      }),
      getAccessToken: async () => accessTokenRef.current,
      graphqlUrl: `${process.env['S_API_BASE_URL']}/graphql`,
      logger: Logger,
      logout,
      onConnected: () => setNetworkErrorCode(null),
      onConnectionError: (code) => setNetworkErrorCode(code),
      refreshAccessToken,
      websocketUrl: process.env['S_API_GQL_WS_URL'] ?? '',
    });
  }, [logout, refreshAccessToken]);

  React.useEffect(() => {
    if (networkErrorCode) {
      // Delay rendering of network error toast by 5 seconds to give us opportunity
      // to recover from transient network issues.
      const timeout = setTimeout(() => {
        setShowNetworkError(true);

        // Debug logging every time network error is shown to user
        Sentry.withScope((scope) => {
          scope.setTag('networkErrorCode', networkErrorCode);
          Logger.error(
            { networkErrorCode },
            `[ApolloProvider] User visible wsClient network error (code: ${networkErrorCode})`
          );
        });
      }, SHOW_NETWORK_ERROR_DELAY_MS);
      return () => clearTimeout(timeout);
    } else {
      setShowNetworkError(false);
    }
  }, [networkErrorCode]);

  return (
    <_ApolloProvider client={gqlClient}>
      {props.children}

      {/* Network Connectivity Warning */}
      <Toast.Container position="top-center">
        <Toast animation show={!!accessToken && !!networkErrorCode && showNetworkError} variant="warning">
          <Toast.Header closeButton={false}>Network Connection Issue</Toast.Header>
          <Toast.Body>
            We&rsquo;re having network difficulties right now. Please check your connectivity.{' '}
            <small>(Code: {networkErrorCode})</small>
          </Toast.Body>
        </Toast>
      </Toast.Container>
    </_ApolloProvider>
  );
}
