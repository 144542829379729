import * as React from 'react';

export interface IOnOverflowChangeArgs {
  isOverflowing: boolean;
  isOverflowingX: boolean;
  isOverflowingY: boolean;
  innerRect: { height: number; width: number };
  outerRect: { height: number; width: number };
}

export interface IOverflowObserverHookProps {
  /**
   * Use `React.useCallback()` to memoize this function.
   */
  onOverflowChange?: (args: IOnOverflowChangeArgs) => void;
}

export default function useOverflowObserver({ onOverflowChange }: IOverflowObserverHookProps) {
  const outerRef = React.useRef<HTMLDivElement>(null);
  const innerRef = React.useRef<HTMLDivElement>(null);

  const [innerRect, setInnerRect] = React.useState<DOMRect | null>(null);
  const [outerRect, setOuterRect] = React.useState<DOMRect | null>(null);
  const [isOverflowing, setIsOverflowing] = React.useState(false);
  const [isOverflowingX, setIsOverflowingX] = React.useState(false);
  const [isOverflowingY, setIsOverflowingY] = React.useState(false);

  React.useEffect(() => {
    const outer = outerRef.current;
    const inner = innerRef.current;
    if (!outer || !inner) {
      return;
    }

    const observer = new ResizeObserver(() => {
      const innerRect = inner.getBoundingClientRect();
      const outerRect = outer.getBoundingClientRect();
      const isOverflowingX = outerRect.width < innerRect.width;
      const isOverflowingY = outerRect.height < innerRect.height;
      const isOverflowing = isOverflowingX || isOverflowingY;

      setInnerRect(innerRect);
      setOuterRect(outerRect);
      setIsOverflowing(isOverflowing);
      setIsOverflowingX(isOverflowingX);
      setIsOverflowingY(isOverflowingY);

      onOverflowChange?.({ isOverflowing, isOverflowingX, isOverflowingY, innerRect, outerRect });
    });

    observer.observe(outerRef.current);
    observer.observe(innerRef.current);

    return () => observer.disconnect();
  }, [onOverflowChange]);

  return { innerRef, outerRef, innerRect, outerRect, isOverflowing };
}
