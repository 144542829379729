import { gql } from '@apollo/client';
import { parseISO } from 'date-fns';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { hexColorWithAlpha } from '../../../lib/css';
import { useAppMeetingState } from '../../../providers/AppMeetingStateProvider';
import CourbetPalette from '../../../providers/ThemeProvider/color-palettes/CourbetPalette';
import LegacyPalette from '../../../providers/ThemeProvider/color-palettes/LegacyPalette';
import LiveVideoBadge from '../../LiveVideoBadge';
import MediaContainer from '../../MediaContainer';
import MediaPlayer from '../../MediaPlayer';
import { PreviewMeetingDuration } from '../../MeetingDuration';

import { CF_LivePreviewMeeting } from './___generated___/LivePreview.types';

const _LiveBadge = styled.div`
  left: 8px;
  position: absolute;
  top: 8px;
`;

export const JoinLink = styled.a`
  align-items: center;
  color: ${LegacyPalette.white};
  display: flex;
  inset: 0;
  justify-content: center;
  opacity: 0.8;
  position: absolute;
  transition: opacity 200ms;

  &:active,
  &:focus,
  &:hover {
    color: ${LegacyPalette.white};
    opacity: 1;
  }

  /* Always show join link on devices that don't support hover state */
  @media (hover: none) {
    opacity: 1;
  }
`;

export const JoinPrompt = styled.div`
  align-items: center;
  background-color: ${hexColorWithAlpha(LegacyPalette.black, 0.4)};
  border: 1px solid ${LegacyPalette.white};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  display: flex;
  padding: 4px 8px;
`;

export const Indicator = styled.div`
  background-color: ${CourbetPalette.red600};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  height: 8px;
  margin-right: 4px;
  width: 8px;
`;

export interface IBaseLivePreviewProps extends ILivePreviewProps {
  hidePreview?: boolean;
  onClick?: () => void;
  overlay?: JSX.Element;
}

export function BaseLivePreview({ hidePreview, meeting, onClick, overlay }: IBaseLivePreviewProps) {
  const { ref, inView } = useInView();

  // Prompt viewers to join the meeting if they are allowed to join and haven't
  // done so already.
  const joinOverlay = meeting.canJoin && onClick && (
    <JoinLink onClick={onClick}>
      <JoinPrompt>
        <Indicator />
        Join Video Chat
      </JoinPrompt>
    </JoinLink>
  );

  const liveBadgeOverlay = (
    <_LiveBadge>
      <LiveVideoBadge invert />
    </_LiveBadge>
  );

  const durationOverlay = meeting.startTime && (
    <PreviewMeetingDuration
      start={parseISO(meeting.startTime)}
      end={meeting.endTime ? parseISO(meeting.endTime) : undefined}
    />
  );

  const content =
    !inView || !meeting.livestreamUrl || hidePreview ? null : (
      <MediaPlayer
        config={{ file: { attributes: { crossOrigin: 'true' } } }}
        height="100%"
        muted
        playing
        playsinline // Prevent default fullscreen playback on mobile.
        url={meeting.livestreamUrl}
        width="100%"
      />
    );

  return (
    <MediaContainer ref={ref}>
      {content}
      {overlay ? (
        overlay
      ) : (
        <>
          {liveBadgeOverlay}
          {joinOverlay}
          {durationOverlay}
        </>
      )}
    </MediaContainer>
  );
}

export interface ILivePreviewProps {
  meeting: CF_LivePreviewMeeting;
  overlay?: JSX.Element;
}

function LivePreview(props: ILivePreviewProps) {
  const { meetingState, showMeeting } = useAppMeetingState();
  const threadId = props.meeting.thread?.id;
  return (
    <BaseLivePreview
      {...props}
      hidePreview={meetingState.threadId === threadId} // Hide preview if user is already in the meeting to avoid confusion around delayed playback
      onClick={threadId ? () => showMeeting({ threadId }) : undefined}
    />
  );
}

LivePreview.fragment = gql`
  fragment CF_LivePreviewMeeting on Meeting {
    id
    canJoin
    endTime
    livestreamUrl
    startTime
    thread {
      id
    }
  }
`;

export default LivePreview;
