import * as React from 'react';

import { CloseIcon } from '../Icon';
import Button, { IButtonProps } from '../ui/Button';

export type CloseButtonProps = Omit<IButtonProps, 'children' | 'icon' | 'shape' | 'type'>;

export default function CloseButton(props: CloseButtonProps) {
  return (
    <Button {...props} aria-label={props['aria-label'] || 'Close'} icon={<CloseIcon />} shape="circle" type="ghost" />
  );
}
