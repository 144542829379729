import { gql } from '@apollo/client';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { ICardProps } from '../../Card';
import MediaContainer from '../../MediaContainer';
import MediaPlayIconOverlay from '../../MediaPlayIconOverlay';
import { PreviewMeetingDuration } from '../../MeetingDuration';
import Shimmer from '../../Shimmer';
import VideoPreviewPlayer, { IBaseVideoPreviewPlayerRef } from '../../VideoPreviewPlayer';

import { CF_MeetingMediaPreview } from './___generated___/MeetingMediaPreview.types';

const _MediaContainer = styled(MediaContainer)`
  cursor: pointer;
`;

export interface IMeetingMediaPreviewRef extends IBaseVideoPreviewPlayerRef {
  unMute: () => void;
}

export interface IMeetingMediaPreviewProps extends Omit<ICardProps, 'onClick'> {
  endTime: Date;
  media: CF_MeetingMediaPreview | null;
  offsetSeconds?: number; // Set initial playback position
  onGoToMeeting?: (offsetSec?: number) => void;
  onPlayerProgress?: (sec: number) => void;
  overlay?: JSX.Element;
  playing?: boolean;
  startTime: Date;
}

const MeetingMediaPreview = React.forwardRef(
  (
    {
      endTime,
      media,
      offsetSeconds,
      onGoToMeeting,
      onPlayerProgress,
      overlay,
      playing,
      startTime,
      ...props
    }: IMeetingMediaPreviewProps,
    ref: React.ForwardedRef<IBaseVideoPreviewPlayerRef>
  ) => {
    const { ref: videoContainerRef, inView } = useInView({ threshold: 0.2 }); // Only play video when more than 20% visible
    const _playing = !!playing && inView;

    const videoPreviewPlayerRef = React.useRef<IBaseVideoPreviewPlayerRef>(null);

    const [previewMuted, setPreviewMuted] = React.useState(true);
    const [previewPlayerProgress, setPreviewPlayerProgress] = React.useState(offsetSeconds ?? 0);
    const [seekTo, setSeekTo] = React.useState<number | null>(null);

    React.useImperativeHandle(ref, () => ({
      seekTo: (sec) => setSeekTo(sec),
      unMute: () => setPreviewMuted(false),
    }));

    React.useEffect(() => {
      // Forward queued seekTo in case we were still loading player when it was called
      if (_playing && seekTo !== null) {
        setSeekTo(null);
        videoPreviewPlayerRef.current?.seekTo(seekTo);
      }
    }, [_playing, seekTo]);

    React.useEffect(() => {
      if (offsetSeconds !== undefined) {
        setPreviewPlayerProgress(offsetSeconds ?? 0);
      }
    }, [offsetSeconds]);

    React.useEffect(() => {
      onPlayerProgress?.(previewPlayerProgress);
    }, [previewPlayerProgress, onPlayerProgress]);

    let preview: JSX.Element;
    if (media) {
      if (_playing) {
        preview = media.url ? (
          <VideoPreviewPlayer
            key={offsetSeconds} // Reset player if offsetSeconds changes
            initialPosition={offsetSeconds ?? previewPlayerProgress} // Resume playback from where it was paused
            media={media}
            muted={previewMuted}
            onMuted={setPreviewMuted}
            onPlayerProgress={setPreviewPlayerProgress}
            playing={_playing}
            ref={videoPreviewPlayerRef}
          />
        ) : (
          <_LoadingShimmer />
        );
      } else {
        preview = media.previewImage?.url ? (
          <_MeetingThumbnailImage src={media.previewImage.url} />
        ) : (
          <_LoadingShimmer />
        );
      }
    } else {
      preview = <_NoMediaPlaceholder />;
    }

    return (
      <_MediaContainer {...props} ref={videoContainerRef}>
        {React.cloneElement(preview, { onClick: () => onGoToMeeting?.(previewPlayerProgress) })}

        {/* Show overlays only when static preview is visible */}
        {!_playing &&
          (overlay ? (
            overlay
          ) : (
            <>
              <PreviewMeetingDuration start={startTime} end={endTime} offsetSeconds={offsetSeconds} />
              <MediaPlayIconOverlay />
            </>
          ))}
      </_MediaContainer>
    );
  }
);

MeetingMediaPreview.displayName = 'MeetingMediaPreview';

const fragment = gql`
  fragment CF_MeetingMediaPreview on Media {
    id
    url
    previewImage {
      id
      url(size: { width: 800, height: 800 })
    }
  }
`;

export default Object.assign(MeetingMediaPreview, { fragment });

const _MeetingThumbnailImage = styled.img`
  object-fit: cover;
  width: 100%;
`;

const _LoadingShimmer = styled(Shimmer)`
  height: 100%;
  width: 100%;
`;

const _NoMediaPlaceholder = styled.div`
  width: 100%;
`;
