import { useLocalStorage } from '@rehooks/local-storage';
import * as React from 'react';

import { ThemeName, isValidThemeName } from '../providers/ThemeProvider/themes';

export const THEME_PREFERENCE_STORAGE_KEY = 'themePref';

export const SYSTEM_THEME_PSEUDONYM = 'system';
export type SYSTEM_THEME_PSEUDONYM = typeof SYSTEM_THEME_PSEUDONYM;

const DEFAULT_THEME_PREFERENCE: ThemeName = 'dark';

interface IThemePreference {
  setThemePreference: (theme: ThemeName | SYSTEM_THEME_PSEUDONYM) => void;
  themePreference: ThemeName | SYSTEM_THEME_PSEUDONYM;

  /**
   * Returns the theme name to use, either the user's preference or the system theme
   */
  themeName: ThemeName;
}

export default function useThemePreference(): IThemePreference {
  const [_themePreference, _setThemePreference] = useLocalStorage<ThemeName | SYSTEM_THEME_PSEUDONYM>(
    THEME_PREFERENCE_STORAGE_KEY
  );

  // Validate the theme preference
  const themePreference =
    _themePreference === SYSTEM_THEME_PSEUDONYM || isValidThemeName(_themePreference)
      ? _themePreference
      : DEFAULT_THEME_PREFERENCE;

  const mediaMatcher = window.matchMedia('(prefers-color-scheme: dark)');
  const [systemTheme, setSystemTheme] = React.useState<ThemeName>(mediaMatcher.matches ? 'dark' : 'light');

  // Subscribe to system theme change events
  React.useEffect(() => {
    const _listener = (event: MediaQueryListEvent) => setSystemTheme(event.matches ? 'dark' : 'light');
    mediaMatcher.addEventListener('change', _listener);
    return () => mediaMatcher.removeEventListener('change', _listener);
  }, [mediaMatcher]);

  return {
    setThemePreference: _setThemePreference,
    themePreference,

    // Return the theme name to use
    themeName: themePreference === SYSTEM_THEME_PSEUDONYM ? systemTheme : themePreference,
  };
}
