import * as React from 'react';
import styled from 'styled-components';

import { truncateText } from '../../lib/css';

export interface IEditableTextProps {
  focus?: boolean;
  maxLength?: number;
  onEdit?: (text: string) => Promise<void>;
  text: string;
}

const _Input = styled.input`
  border-width: 1px;
  max-width: 100%;
  padding: 1px 0;
  width: 100%;

  :focus {
    border-color: ${({ theme }) => theme.color.secondaryColor};
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    outline: none !important;
  }
`;

const _Text = styled.div`
  cursor: text;
  padding: 2px 1px;

  :hover {
    border-color: ${({ theme }) => theme.color.secondaryColor};
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    padding: 1px 0;
  }
  ${truncateText()}
`;

function EditableText(props: IEditableTextProps) {
  const [isEditing, setIsEditing] = React.useState<boolean>(!!props.focus);
  const [text, setText] = React.useState<string>(props.text);

  function reset() {
    setIsEditing(false);
    setText(props.text);
  }

  return isEditing ? (
    <form
      onSubmit={async (event) => {
        event.preventDefault();
        await props.onEdit?.(text.slice(0, props.maxLength));
        setIsEditing(false);
      }}
      style={{ display: 'inline' }}
    >
      <_Input
        autoFocus
        maxLength={props.maxLength}
        size={Math.max(1, text.length)}
        type="text"
        value={text}
        onChange={(event) => {
          setText(event.target.value);
        }}
        onBlur={async (event) => {
          await props.onEdit?.(text);
          setIsEditing(false);
        }}
        onKeyDown={(event) => {
          if (event.key == 'Escape') {
            reset();
          }
        }}
      />
    </form>
  ) : (
    <_Text
      onClick={() => {
        setText(props.text);
        setIsEditing(true);
      }}
      title={props.text}
    >
      {props.text}
    </_Text>
  );
}

export default EditableText;
