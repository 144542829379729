import * as React from 'react';

type Ref<T> = React.RefCallback<T> | React.MutableRefObject<T | null> | React.ForwardedRef<T>;

function setRef<T>(ref: Ref<T>, value: T) {
  if (!ref) {
    return;
  }

  if (typeof ref === 'function') {
    ref(value);
  } else if ('current' in ref) {
    ref.current = value;
  }
}

/**
 * Utility for passing multiple refs to a component.
 *
 * Usage:
 * ```
 * <Component
 *   {...props}
 *   ref={mergeRefs(someRef, anotherRef, thirdRef)}
 * />
 * ```
 */
export default function mergeRefs<T>(...refs: Array<Ref<T>>) {
  return (value: T) => {
    refs.forEach((ref) => setRef(ref, value));
  };
}
