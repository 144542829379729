import debounce from 'lodash/debounce';
import * as React from 'react';
import styled from 'styled-components';

import { ChevronDownIcon } from '../Icon';
import OverflowObserver from '../OverflowWrapper';
import { IOnOverflowChangeArgs } from '../OverflowWrapper/useOverflowObserver';
import Button from '../ui/Button';

const OVERFLOW_DEBOUNCE_INTERVAL_MS = 100;

export interface IOverflowMaskProps extends React.PropsWithChildren<unknown> {
  expandable?: boolean;
  maxHeight?: string;
}

const _Mask = styled(OverflowObserver).attrs({ direction: 'vertical' })<{ $overflowing: boolean; maxHeight?: string }>`
  flex-shrink: 0;
  mask-image: ${({ $overflowing }) => ($overflowing ? 'linear-gradient(to top, transparent, black 2rem)' : undefined)};
  mask-mode: alpha;
  mask-position: bottom;
  max-height: ${({ maxHeight }) => maxHeight};
  min-height: 1.5rem;
  overflow: hidden;
`;

const _SeeMore = styled(Button).attrs({ size: 'small', type: 'ghost' })`
  color: ${({ theme }) => theme.color.color};
  padding: 0;

  &:hover {
    background-color: inherit;
  }
`;

function OverflowMask({ children, expandable, maxHeight }: IOverflowMaskProps) {
  const [allowedHeight, setAllowedHeight] = React.useState<string | undefined>(maxHeight);
  const [overflowing, setOverflowing] = React.useState(false);

  const _debouncedSetOverflowing = React.useMemo(() => debounce(setOverflowing, OVERFLOW_DEBOUNCE_INTERVAL_MS), []);
  const _onOverflowChange = React.useCallback(
    ({ isOverflowingY }: IOnOverflowChangeArgs) => _debouncedSetOverflowing(isOverflowingY),
    [_debouncedSetOverflowing]
  );

  return (
    <>
      <_Mask maxHeight={allowedHeight} $overflowing={overflowing} onOverflowChange={_onOverflowChange}>
        {children}
      </_Mask>
      {overflowing && expandable && (
        <_SeeMore onClick={() => setAllowedHeight(undefined)}>
          See More <ChevronDownIcon />
        </_SeeMore>
      )}
    </>
  );
}

export default OverflowMask;
