import * as React from 'react';
import styled from 'styled-components';

import Shimmer from '../../../Shimmer';

const _ElementPlaceholderContainer = styled(Shimmer)`
  height: 126px;
  width: 168px;
`;

export default function ElementPlaceholder(props: React.HTMLAttributes<HTMLDivElement>) {
  return <_ElementPlaceholderContainer {...props}>{props.children}</_ElementPlaceholderContainer>;
}
