import * as React from 'react';

export interface IListFormatProps {
  items: JSX.Element[];
  limit?: number;
}

/**
 * Renders a list of items with appropriate conjunctions.
 */
export default function ListFormat(props: IListFormatProps) {
  if (!props.items.length) {
    return null;
  }

  if (props.items.length === 1) {
    return props.items[0];
  }

  if (props.items.length === 2) {
    return (
      <>
        {props.items[0]} and {props.items[1]}
      </>
    );
  }

  if (props.limit && props.items.length > props.limit) {
    const displayedItems = props.items.slice(0, props.limit);
    const truncatedItems = props.items.slice(props.limit);

    return (
      <>
        {displayedItems.map((item) => (
          <React.Fragment key={item.key}>
            {item}
            {', '}
          </React.Fragment>
        ))}
        and {truncatedItems.length} more
      </>
    );
  }

  return (
    <>
      {props.items.slice(0, -1).map((item) => (
        <React.Fragment key={item.key}>
          {item}
          {', '}
        </React.Fragment>
      ))}
      and {props.items.slice(-1)[0]}
    </>
  );
}
