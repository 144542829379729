import * as React from 'react';
import styled from 'styled-components';

import { truncateText } from '../../../../lib/css';

const PADDING_LEFT = '0.214em'; /* 3px @ 14px base font */
const PADDING_RIGHT = '0.286em'; /* 4px @ 14px base font */
const PADDING_Y = '0';

/**
 * !!NOTE!! we define as many of the spacing values in terms of `em` to allow for easy scaling
 * of the Pill based on the font-size of the parent element.
 */
const _Pill = styled.span<{ $hasIcon?: boolean; $nonInteractive?: boolean; $selected?: boolean }>`
  align-items: center;
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.color.textSelectionBackground : theme.color.background400};
  border-radius: 0.286em; /* 4px @ 14px base font */
  color: ${({ $selected, theme }) => ($selected ? theme.color.textSelectionColor : theme.color.color)};
  display: inline-flex;
  font-size: 0.929em; /* 13px @ 14px base font */
  gap: 0.286em; /* 4px @ 14px base font */
  max-width: 100%;
  overflow: hidden;
  padding: ${({ $hasIcon }) =>
    $hasIcon ? `${PADDING_Y} ${PADDING_RIGHT} ${PADDING_Y} ${PADDING_LEFT}` : `${PADDING_Y} ${PADDING_RIGHT}`};
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  vertical-align: ${({ $hasIcon }) => ($hasIcon ? 'bottom' : 'baseline')};
  white-space: nowrap;

  &:hover {
    background-color: ${({ $nonInteractive, $selected, theme }) =>
      !$nonInteractive && !$selected ? theme.color.background200 : undefined};
  }
`;

const _IconWrapper = styled.span`
  display: inline-flex;
  flex-shrink: 0;
`;

const _Content = styled.span`
  ${truncateText()};
`;

export interface IBasePillProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: React.ReactNode;
  nonInteractive?: boolean;
  selected?: boolean;
}

function BasePill({ children, icon, nonInteractive, selected, ...props }: React.PropsWithChildren<IBasePillProps>) {
  return (
    <_Pill $hasIcon={!!icon} $nonInteractive={nonInteractive} $selected={selected} {...props}>
      {icon && <_IconWrapper>{icon}</_IconWrapper>}
      <_Content>{children}</_Content>
    </_Pill>
  );
}

export default BasePill;
