import * as React from 'react';

import Icon, { IIconProps } from '../Icon';

export default function MicrosoftIcon(props: IIconProps) {
  return (
    <Icon {...props}>
      <path stroke="none" strokeWidth="0" fill="currentColor" d="M5.78947 2H2V5.78947H5.78947V2Z" />
      <path stroke="none" strokeWidth="0" fill="currentColor" d="M5.78947 6.21051H2V9.99998H5.78947V6.21051Z" />
      <path stroke="none" strokeWidth="0" fill="currentColor" d="M9.99992 2H6.21045V5.78947H9.99992V2Z" />
      <path stroke="none" strokeWidth="0" fill="currentColor" d="M9.99992 6.21051H6.21045V9.99998H9.99992V6.21051Z" />
    </Icon>
  );
}
