import * as React from 'react';
import styled from 'styled-components';

export type SpaceAlign = 'start' | 'center' | 'end' | 'baseline';
export type SpaceDirection = 'horizontal' | 'vertical';
export type SpaceSize = 'small' | 'medium' | 'large' | number;

const DEFAULT_SIZE = 8;

function getSizeValue(size: SpaceSize) {
  switch (size) {
    case 'small':
      return DEFAULT_SIZE / 2;
    case 'medium':
      return DEFAULT_SIZE;
    case 'large':
      return DEFAULT_SIZE * 2;
    default:
      return size;
  }
}

interface IStyledSpaceProps {
  $align: SpaceAlign;
  $direction: SpaceDirection;
  $size: SpaceSize;
  $wrap: boolean;
}

const _Space = styled.div<IStyledSpaceProps>`
  align-items: ${({ $align }) => $align};
  display: inline-flex;
  flex-direction: ${({ $direction }) => ($direction === 'vertical' ? 'column' : 'row')};
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  flex-wrap: ${({ $wrap }) => ($wrap ? 'wrap' : 'nowrap')};
  gap: ${({ $size }) => getSizeValue($size)}px;
`;

export interface ISpaceProps extends React.HTMLAttributes<HTMLDivElement> {
  align?: SpaceAlign;
  direction?: 'horizontal' | 'vertical';
  size?: SpaceSize;
  wrap?: boolean;
}

function Space({
  align = 'center',
  direction = 'horizontal',
  size = DEFAULT_SIZE,
  wrap = false,
  ...props
}: ISpaceProps) {
  return <_Space {...props} $align={align} $direction={direction} $size={size} $wrap={wrap} />;
}

export default Space;
