import * as React from 'react';
import styled from 'styled-components';

import Badge, { IBadgeProps } from '../Badge';

const _ChildContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const _BadgeContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
`;

export interface ICountProps extends IBadgeProps {
  /**
   * The count to display in the badge.
   */
  count?: number;
  /**
   * The maximum count to display. Any `count` higher than this amount will be
   * abbreviated, eg: "99+" instead of "100".
   */
  overflowCount?: number;
  /**
   * Whether or not to show the count when it's zero.
   */
  showZero?: boolean;
}

/**
 * A badge-based component specifically for showing notification-type counts.
 */
function Count({ children, count = 0, overflowCount = 99, showZero, ...props }: ICountProps) {
  if (!showZero && count === 0) {
    return children ? <>{children}</> : null;
  }

  const displayCount = count > overflowCount ? `${overflowCount}+` : count;
  const badge = <Badge {...props}>{displayCount}</Badge>;

  return children ? (
    <_ChildContainer>
      {children}
      <_BadgeContainer>{badge}</_BadgeContainer>
    </_ChildContainer>
  ) : (
    badge
  );
}

export default Count;
