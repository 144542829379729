import { ApolloClient, ApolloError, QueryHookOptions, gql, useQuery } from '@apollo/client';

import {
  GetExternalResourceQuery,
  GetExternalResourceQueryVariables,
} from './___generated___/useGetExternalResourceQuery.types';

const HF_GetExternalResource = gql`
  fragment HF_GetExternalResource on AppObject {
    id
    app
    name
    permalink
  }
`;

export const GetExternalResourceQueryGQL = gql`
  ${HF_GetExternalResource}
  query GetExternalResourceQuery($url: String) {
    link(url: $url) {
      id
      processed
      appObject {
        ...HF_GetExternalResource
      }
    }
  }
`;

export function useGetExternalResourceQuery(
  input: GetExternalResourceQueryVariables,
  options?: Omit<QueryHookOptions<GetExternalResourceQuery, GetExternalResourceQueryVariables>, 'variables'>
): {
  loading: boolean;
  error?: ApolloError;
  data?: GetExternalResourceQuery;
} {
  return useQuery<GetExternalResourceQuery, GetExternalResourceQueryVariables>(GetExternalResourceQueryGQL, {
    ...options,
    variables: input,
  });
}

useGetExternalResourceQuery.fragment = HF_GetExternalResource;

export async function queryExternalResource(client: ApolloClient<object>, url: string) {
  const result = await client.query<GetExternalResourceQuery, GetExternalResourceQueryVariables>({
    query: GetExternalResourceQueryGQL,
    variables: { url },
  });
  return result.data.link?.appObject?.id;
}
