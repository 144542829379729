import * as React from 'react';
import styled, { css } from 'styled-components';

import { useMediaThumbnailUrlQuery } from '../../hooks/useMediaThumbnailUrlQuery';
import { hexColorWithAlpha } from '../../lib/css';
import LegacyPalette from '../../providers/ThemeProvider/color-palettes/LegacyPalette';
import Duration from '../Duration';
import { AudioIcon, AudioOffIcon } from '../Icon';
import { IMediaPlayerRef } from '../MediaPlayer';
import Spinner from '../Spinner';
import ThumbnailCard, { THUMBNAIL_CARD_DEFAULT_WIDTH_PX } from '../ThumbnailCard';
import Button from '../ui/Button';

import { VideoPreviewPlayerQuery } from './___generated___/useVideoPreviewPlayerQuery.types';

const MediaPlayer = React.lazy(() => import('../MediaPlayer'));

const PROGRESS_UPDATE_INTERVAL_MS = 200;
const SCRUBBER_BAR_HEIGHT_PX = 6;
const THUMBNAIL_SNAP_INTERVAL_S = 5;

const durationStyle = css`
  background-color: ${hexColorWithAlpha(LegacyPalette.black, 0.4)};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  color: ${LegacyPalette.white};
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  padding: 0 2px;
`;

const _Container = styled.div`
  background-color: ${LegacyPalette.black};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const _MediaPlayer = styled(MediaPlayer)`
  inset: 0;
  position: absolute;
`;

const _PreviewImageContainer = styled.div<{ $visible: boolean }>`
  height: 100%;
  inset: 0;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  position: absolute;
  transition: opacity 500ms ease-out;
  width: 100%;
`;

const _PreviewImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const _ControlsContainer = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  right: 8px;
  top: 8px;
`;

const _DurationContainer = styled.div<{ $visible: boolean }>`
  bottom: ${SCRUBBER_BAR_HEIGHT_PX + 8}px;
  display: block;
  left: 8px;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  position: absolute;
  transition: opacity 200ms;
  ${durationStyle}
`;

const _ScrubberContainer = styled.div`
  bottom: 0;
  cursor: pointer;
  height: ${SCRUBBER_BAR_HEIGHT_PX * 4}px;
  position: absolute;
  width: 100%;
`;

const _ScrubberBar = styled.div<{ $hover: boolean }>`
  background-color: ${({ $hover }) => hexColorWithAlpha(LegacyPalette.white, $hover ? 0.5 : 0.25)};
  bottom: 0;
  height: ${SCRUBBER_BAR_HEIGHT_PX}px;
  position: absolute;
  transition: background-color 200ms ease-in-out;
  width: 100%;
`;

const _LoadedBar = styled.div`
  background-color: ${hexColorWithAlpha(LegacyPalette.white, 0.5)};
  bottom: 0;
  height: ${SCRUBBER_BAR_HEIGHT_PX}px;
  position: absolute;
  transition: width ${PROGRESS_UPDATE_INTERVAL_MS}ms linear;
`;

const _PlayedBar = styled.div`
  background-color: ${({ theme }) => theme.color.lightPurple};
  bottom: 0;
  height: ${SCRUBBER_BAR_HEIGHT_PX}px;
  position: absolute;
  transition: width ${PROGRESS_UPDATE_INTERVAL_MS}ms linear;
`;

const _ThumbnailCardContainer = styled.div<{ $visible: boolean; $left: number }>`
  bottom: ${SCRUBBER_BAR_HEIGHT_PX + 16}px;
  left: ${({ $left }) => $left}px;
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  position: absolute;
  transition: opacity 200ms;
`;

export interface IBaseVideoPreviewPlayerRef {
  seekTo: (sec: number) => void;
}

export interface IBaseVideoPreviewPlayerProps extends React.HTMLAttributes<HTMLDivElement> {
  initialPosition?: number;
  mediaUrl?: string | null;
  muted?: boolean;
  onFetchThumbnailUrl?: (offsetSec: number) => Promise<string | null>;
  onMuted?: (muted: boolean) => void;
  onPlayerProgress?: (sec: number) => void;
  playing?: boolean;
  previewUrl?: string | null;
}

/**
 * Used to embed a video player for previewing videos.
 */
export const BaseVideoPreviewPlayer = React.forwardRef(
  (
    {
      initialPosition,
      mediaUrl,
      muted: _muted,
      onFetchThumbnailUrl,
      onMuted,
      onPlayerProgress,
      playing,
      previewUrl,
      ...props
    }: IBaseVideoPreviewPlayerProps,
    ref: React.ForwardedRef<IBaseVideoPreviewPlayerRef>
  ) => {
    const [duration, setDuration] = React.useState<number | undefined>(undefined);
    const [loadedProgressPct, setLoadedProgressPct] = React.useState<number | undefined>(undefined);
    const [muted, setMuted] = React.useState(_muted ?? true);
    const [playedProgressPct, setPlayedProgressPct] = React.useState<number | undefined>(undefined);
    const [playerReady, setPlayerReady] = React.useState<boolean>(false);
    const [scrubberHover, setScrubberHover] = React.useState(false);
    const [scrubberHoverPositionPct, setScrubberHoverPositionPct] = React.useState<number | undefined>(undefined);
    const [thumbnailUrl, setThumbnailUrl] = React.useState<string | null>(null);

    const containerRef = React.useRef<HTMLDivElement>(null);
    const playerRef = React.useRef<IMediaPlayerRef>(null);

    React.useImperativeHandle(ref, () => ({
      seekTo: (sec) => playerRef.current?.seekTo(sec),
    }));

    React.useEffect(() => setMuted(_muted ?? true), [_muted]);

    React.useEffect(
      () => {
        if (initialPosition === undefined) {
          return;
        }

        if (initialPosition > 0 && initialPosition < 1) {
          if (!duration) {
            return; // can't seek to a percentage without knowing the duration
          }
          playerRef.current?.seekTo(initialPosition * duration);
        } else {
          playerRef.current?.seekTo(initialPosition);
        }
      },

      // We only want to run this effect once, so we don't
      // need to include `initialPosition` in the dependencies.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [duration]
    );

    // Discretize thumbnail position to nearest THUMBNAIL_SNAP_INTERVAL_S seconds.
    const thumbnailSnapToTime =
      duration && scrubberHoverPositionPct !== undefined
        ? Math.floor((scrubberHoverPositionPct * duration) / THUMBNAIL_SNAP_INTERVAL_S) * THUMBNAIL_SNAP_INTERVAL_S
        : 0;

    React.useEffect(() => {
      if (!onFetchThumbnailUrl) {
        setThumbnailUrl(null);
        return;
      }
      onFetchThumbnailUrl(thumbnailSnapToTime)
        .then((url) => setThumbnailUrl(url))
        .catch(() => setThumbnailUrl(null));
    }, [onFetchThumbnailUrl, thumbnailSnapToTime]);

    const content = !mediaUrl ? (
      <Spinner fullScreen size="xLarge" />
    ) : (
      <>
        <_MediaPlayer
          height="100%"
          muted={muted}
          onDuration={(d) => setDuration(d)}
          onProgress={(p) => {
            onPlayerProgress?.(p.playedSeconds);
            setPlayedProgressPct(p.played);
            setLoadedProgressPct(p.loaded);
          }}
          onPlay={() => setPlayerReady(true)}
          onSeek={(sec) => duration && setPlayedProgressPct(sec / duration)}
          playing={playing}
          // Prevent default fullscreen playback on mobile.
          playsinline
          progressInterval={PROGRESS_UPDATE_INTERVAL_MS}
          ref={playerRef}
          url={mediaUrl}
          width="100%"
        />

        {previewUrl && (
          <_PreviewImageContainer $visible={!playerReady}>
            <_PreviewImage src={previewUrl} />
          </_PreviewImageContainer>
        )}

        <_ControlsContainer onClick={(e) => e.stopPropagation()}>
          <Button
            active={!muted}
            icon={muted ? <AudioOffIcon /> : <AudioIcon />}
            onClick={(e) => {
              setMuted((last) => !last);
              onMuted?.(!muted);
            }}
            size="medium"
          />
        </_ControlsContainer>

        <_DurationContainer $visible={!!duration && !scrubberHover}>
          <Duration seconds={(playedProgressPct ?? 0) * (duration ?? 0)} />
          &nbsp;/&nbsp;
          <Duration seconds={duration ?? 0} />
        </_DurationContainer>

        <_ScrubberContainer
          onClick={(e) => {
            e.stopPropagation(); // intercept
            const targetRect = e.currentTarget.getBoundingClientRect();
            const relativeXPos = (e.clientX - targetRect.x) / targetRect.width;
            playerRef.current?.seekTo(relativeXPos);
          }}
          onMouseMove={(e) => {
            const targetRect = e.currentTarget.getBoundingClientRect();
            const relativeXPos = (e.clientX - targetRect.x) / targetRect.width;
            setScrubberHoverPositionPct(relativeXPos);
          }}
          onMouseOut={() => setScrubberHover(false)}
          onMouseOver={() => setScrubberHover(true)}
        >
          <_ScrubberBar $hover={scrubberHover} />
          <_LoadedBar style={{ width: `${(loadedProgressPct ?? 0) * 100}%` }} />
          <_PlayedBar style={{ width: `${(playedProgressPct ?? 0) * 100}%` }} />
        </_ScrubberContainer>

        <_ThumbnailCardContainer
          $visible={!!thumbnailUrl && scrubberHover}
          $left={Math.min(
            Math.max(
              0,
              (scrubberHoverPositionPct ?? 0) * (containerRef.current?.clientWidth ?? 0) -
                THUMBNAIL_CARD_DEFAULT_WIDTH_PX / 2
            ),
            (containerRef.current?.clientWidth ?? 0) - THUMBNAIL_CARD_DEFAULT_WIDTH_PX
          )}
        >
          <ThumbnailCard
            timestampSeconds={(duration ?? 0) * (scrubberHoverPositionPct ?? 0)}
            thumbnailUrl={thumbnailUrl}
          />
        </_ThumbnailCardContainer>
      </>
    );

    return (
      <React.Suspense fallback={<Spinner fullScreen />}>
        <_Container ref={containerRef} {...props}>
          {content}
        </_Container>
      </React.Suspense>
    );
  }
);

BaseVideoPreviewPlayer.displayName = 'BaseVideoPreviewPlayer';

////////////////////////////////////////

export interface IVideoPreviewPlayerProps
  extends React.HTMLAttributes<HTMLDivElement>,
    Pick<IBaseVideoPreviewPlayerProps, 'initialPosition' | 'muted' | 'onMuted' | 'onPlayerProgress' | 'playing'> {
  media: VideoPreviewPlayerQuery['media'];
}

const VideoPreviewPlayer = React.forwardRef(
  (
    { initialPosition, media, muted, onMuted, onPlayerProgress, playing, ...props }: IVideoPreviewPlayerProps,
    ref: React.ForwardedRef<IBaseVideoPreviewPlayerRef>
  ) => {
    const thumbnailQuery = useMediaThumbnailUrlQuery({ mediaId: media?.id });

    return (
      <BaseVideoPreviewPlayer
        {...props}
        initialPosition={initialPosition}
        mediaUrl={media?.url}
        muted={muted}
        onFetchThumbnailUrl={thumbnailQuery}
        onMuted={onMuted}
        onPlayerProgress={onPlayerProgress}
        playing={playing}
        previewUrl={media?.previewImage?.url}
        ref={ref}
      />
    );
  }
);

VideoPreviewPlayer.displayName = 'VideoPreviewPlayer';

export default VideoPreviewPlayer;
