import { createGlobalStyle } from 'styled-components';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './fonts.css';
import './fonts/inter-4.0/inter.css';

const GlobalStyles = createGlobalStyle`
  :root {
    color-scheme: ${({ theme }) => theme.spec.scheme};
  }

  :focus-visible {
    outline-color: ${({ theme }) => theme.color.variant.primary.color};
  }

  ::selection {
    background-color: ${({ theme }) => theme.color.textSelectionBackground};
    color: ${({ theme }) => theme.color.textSelectionColor};
  }

  html {
    font-size: ${({ theme }) => theme.fontSize.root};
  }
  
  body {
    background: ${({ theme }) => theme.color.background800};
    color: ${({ theme }) => theme.color.color};
    font-family: Inter, sans-serif;;
    font-feature-settings: "kern" 1, "dlig" 1, "liga" 1, "calt" 1, "tnum" 0, "ss01" 1, "ss04" 1; /* enable kerning, ligatures, disable tabular numbers by default, open digits, disambiguation */
    font-size: 1rem; /* anchor to root size */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    height: 100%;
    line-height: 1.5;
    margin: 0;
    position: fixed;
  }

  @supports (font-variation-settings: normal) {
    body {
      font-family: InterVariable, sans-serif;
      font-optical-sizing: auto;
    }
  }

  div {
    scrollbar-width: thin;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.color.color};
  }

  h1 {
    font-size: ${({ theme }) => theme.fontSize.h1};
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSize.h2};
  }

  h3 {
    font-size: ${({ theme }) => theme.fontSize.h3};
  }

  h4 {
    font-size: ${({ theme }) => theme.fontSize.h4};
  }

  h5 {
    font-size: ${({ theme }) => theme.fontSize.h5};
  }

  h6 {
    font-size: ${({ theme }) => theme.fontSize.h6};
  }

  p {
    font-size: ${({ theme }) => theme.fontSize.p};
  }

  small {
    font-size: ${({ theme }) => theme.fontSize.xSmall};
  }

  a {
    color: ${({ theme }) => theme.color.variant.primary.text};

    &:hover {
      color: ${({ theme }) => theme.color.variant.primary.text};
    }
  }

  code,
  pre {
    background-color: ${({ theme }) => theme.color.background500};
    border: 1px solid ${({ theme }) => theme.color.border};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    font-family: 'Roboto Mono', monospace;
    font-size: 85%;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }

  code {
    color: ${({ theme }) => theme.color.color};
    padding: 0 4px;
  }

  pre code {
    border: 0;
  }

  pre {
    padding: 8px 12px;
  }

  blockquote {
    border-left: 4px solid ${({ theme }) => theme.color.border};
    color: ${({ theme }) => theme.color.secondaryColor};
    margin: 8px 0;
    padding: 0 12px;
  }
`;

export default GlobalStyles;
