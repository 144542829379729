import * as React from 'react';

import mergeRefs from '../../lib/mergeRefs';

export interface IInteractiveProps extends React.HTMLAttributes<HTMLElement> {
  as?: React.ElementType;
  onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
}

/**
 * A generic interactive element. This is mainly useful for building components
 * with nested interactive elements, since nesting buttons and anchors is
 * invalid.
 *
 * We should strongly prefer using the correct semantic elements and avoid
 * using this component if at all possible.
 */
const Interactive = React.forwardRef<HTMLElement, IInteractiveProps>(
  ({ as = 'div', role = 'button', tabIndex = 0, ...props }, ref) => {
    const elementRef = React.useRef<HTMLElement>(null);

    function onKeyDown(e: React.KeyboardEvent<HTMLElement>) {
      if (e.key === 'Enter') {
        // Trigger a click when the user hits enter, as a button or link does.
        elementRef.current?.click();
      }
      props.onKeyDown?.(e);
    }

    const Component = as;

    return (
      <Component {...props} onKeyDown={onKeyDown} ref={mergeRefs(ref, elementRef)} role={role} tabIndex={tabIndex} />
    );
  }
);

Interactive.displayName = 'Interactive';

export default Interactive;
