import { ApolloClient, Reference } from '@apollo/client';

export const TYPING_TIMEOUT_MS = 2500;

type TypedThreadId = { id: string; __typename: 'Discussion' | 'Meeting' | 'PrivateChat' | 'Replies' | 'Team' };
export default class ThreadClientState {
  static _threadClientState = new Map<string, ThreadClientState>();

  static get(thread: TypedThreadId): ThreadClientState {
    return this._threadClientState.get(thread.id) || new ThreadClientState(thread);
  }

  threadId: string;
  threadType: string;
  visible?: boolean;
  sortTime?: Date;
  stationary?: boolean;
  typingTimers = new Map<string, NodeJS.Timeout>();

  constructor(thread: TypedThreadId) {
    this.threadId = thread.id;
    this.threadType = thread.__typename;
    ThreadClientState._threadClientState.set(thread.id, this);
    return this;
  }

  public async addTyper(client: ApolloClient<object>, accountId: string) {
    const existingTimer = this.typingTimers.get(accountId);
    if (existingTimer) {
      clearTimeout(existingTimer);
    }
    // start a fresh timer
    const timer = setTimeout(async () => await this.removeTyper(client, accountId), TYPING_TIMEOUT_MS);
    ThreadClientState._threadClientState.get(this.threadId)?.typingTimers.set(accountId, timer);
  }

  public async removeTyper(client: ApolloClient<object>, accountId?: string | null) {
    if (!accountId) {
      return;
    }
    // remove from cache
    const a = await client.cache.modify({
      id: client.cache.identify({ __typename: this.threadType, id: this.threadId }),
      fields: {
        typing(cached: ReadonlyArray<Reference>, { readField }) {
          return cached.filter((u) => readField('id', u) != accountId);
        },
      },
    });
    clearTimeout(this.typingTimers.get(accountId));
    this.typingTimers.delete(accountId);
  }
}
