import * as React from 'react';
import RBAlert, { AlertProps as RBAlertProps } from 'react-bootstrap/Alert';
import styled from 'styled-components';

import { IColorThemeVariants } from '../../providers/ThemeProvider/color-themes/IColorTheme';
import CloseButton from '../CloseButton';

export type AlertProps = Omit<RBAlertProps, 'dismissible'>;
export type AlertVariant = keyof IColorThemeVariants;

const _Alert = styled(RBAlert)<{ $variant: AlertVariant }>`
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border-width: 0 0 0 4px;
  margin-bottom: 16px;
  padding: 12px 16px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme, $variant }) => {
    const variantTheme = theme.color.variant[$variant];
    return `
      background-color: ${variantTheme.background};
      border-color: ${variantTheme.border};
      color: ${variantTheme.text};
    `;
  }}

  &.alert-dismissible {
    padding-right: 16px;
  }

  & .btn-close {
    /* Hide the default RB close button. */
    display: none;
  }
`;

const _CloseButton = styled(CloseButton)<{ $variant: AlertVariant }>`
  color: inherit;
  position: absolute;
  right: 8px;
  top: 5px;

  &:hover {
    background-color: inherit;
    border: ${({ theme, $variant }) => `1px solid ${theme.color.variant[$variant].border}`};
    color: inherit;
  }
`;

const _Link = styled.a`
  color: inherit;
  font-weight: bold;

  &:active,
  &:focus,
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

export interface IAlertProps extends Omit<AlertProps, 'closeVariant' | 'onClose' | 'variant'> {
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: AlertVariant;
}

function Alert({ children, closeLabel = 'Close alert', onClose, variant = 'primary', ...props }: IAlertProps) {
  return (
    <_Alert {...props} dismissible={!!onClose} $variant={variant}>
      {onClose && <_CloseButton aria-label={closeLabel} onClick={onClose} size="medium" $variant={variant} />}
      {children}
    </_Alert>
  );
}

Alert.Heading = RBAlert.Heading;
Alert.Link = _Link;

export default Alert;
