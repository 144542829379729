import * as React from 'react';
import type { SVGProps } from 'react';
import UntitledUIIconWrapper, { IIconWrapperProps } from '../UntitledUIIconWrapper';
function ChevronRightOutlineIcon({
  className,
  role,
  size,
  ...props
}: IIconWrapperProps & Omit<SVGProps<SVGSVGElement>, 'height' | 'width' | 'strokeWidth'>) {
  return React.createElement(
    UntitledUIIconWrapper,
    {
      role,
      size,
      ...props,
    },
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, 'uiiIcon', 'uiIcon-ChevronRightOutlineIcon'].filter((c) => !!c).join(' ')}
      {...props}
    >
      <path d="M9 18L15 12L9 6" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
export default ChevronRightOutlineIcon;
