import * as React from 'react';
import RBAccordion, { AccordionProps } from 'react-bootstrap/Accordion';
import styled from 'styled-components';

import { truncateText } from '../../lib/css';

export { useAccordionButton } from 'react-bootstrap/AccordionButton';

const _Accordion = styled(RBAccordion)``;

const _AccordionItem = styled(RBAccordion.Item)`
  background-color: transparent;
  border-bottom: 0;
  color: ${({ theme }) => theme.color.color};
  padding: 0;
`;

const _AccordionHeader = styled(RBAccordion.Header)`
  font-size: ${({ theme }) => theme.fontSize.h6};
  ${truncateText()}

  & .accordion-button {
    background-color: transparent;
    color: ${({ theme }) => theme.color.color};
    padding: 4px 0;

    :hover,
    :focus {
      box-shadow: none;
    }

    :not(.collapsed) {
      background-color: ${({ theme }) => theme.color.background400};
      background-image: none;
      box-shadow: none;
      color: ${({ theme }) => theme.color.color};
    }
  }
`;

const _AccordionBody = styled(RBAccordion.Body)`
  font-size: ${({ theme }) => theme.fontSize.p};
  padding: 4px;
`;

export type IAccordionProps = Omit<AccordionProps, 'flush'>;

function Accordion(props: React.PropsWithChildren<IAccordionProps>) {
  return (
    <_Accordion {...props} flush>
      {props.children}
    </_Accordion>
  );
}

Accordion.Item = _AccordionItem;
Accordion.Header = _AccordionHeader;
Accordion.Body = _AccordionBody;
Accordion.Collapse = RBAccordion.Collapse;

export default Accordion;
