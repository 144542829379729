import * as React from 'react';
import styled from 'styled-components';

import { TypeaheadProfileQueryProfileType } from '../../../___generated___/globalTypes';
import { truncateText } from '../../../lib/css';
import { CanonicalSizePx } from '../../../providers/ThemeProvider/themes';
import { ProfileAvatar } from '../../Avatar';
import Dropdown from '../../Dropdown';
import { LockIcon } from '../../Icon';
import SearchResultHighlighter from '../../search/SearchResultHighlighter';
import Typeahead, { ItemProps, TypeaheadProps, TypeaheadRef } from '../Typeahead';

import {
  IProfileNameTypeaheadOption,
  useProfileNameTypeaheadOptionsQuery,
} from './useProfileNameTypeaheadOptionsQuery';

const _Result = styled(Typeahead.Item)`
  align-items: center;
  display: flex;
  gap: 8px;
  line-height: 1.2;
  padding: 4px 16px;
`;

const _ResultContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
`;

const _ResultName = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.p};
  ${truncateText()}
`;

export function ProfileNameTypeaheadItem({ searchMetadata, ...profile }: IProfileNameTypeaheadOption) {
  const isPrivate =
    (profile.__typename === 'Discussion' || profile.__typename === 'Team') && profile.audience === 'PRIVATE';
  const highlightedName = searchMetadata?.highlights?.name ? (
    <SearchResultHighlighter text={searchMetadata.highlights.name} />
  ) : (
    profile.name
  );

  return (
    <>
      <div title={`${searchMetadata?.score}`}>
        <ProfileAvatar size={CanonicalSizePx.small} profile={profile} />
      </div>
      <_ResultContent>
        <_ResultName>
          {isPrivate ? (
            <>
              <LockIcon size="xSmall" />
              &nbsp;
            </>
          ) : null}
          {highlightedName}
        </_ResultName>
      </_ResultContent>
    </>
  );
}

export function getHeaderLabelFromType(type: string) {
  switch (type) {
    case 'Account':
      return 'People';
    case 'Discussion':
      return 'Discussions';
    case 'Team':
      return 'Teams';
    case 'Meeting':
      return 'Video Chats';
    default:
      return 'Unknown';
  }
}

export function groupResultsByType(results: IProfileNameTypeaheadOption[], prioritizedGroups?: string[]) {
  const groupedResults: Record<string, IProfileNameTypeaheadOption[]> = Object.fromEntries(
    prioritizedGroups?.map((group) => [group, []]) ?? []
  );

  results.forEach((result) => {
    const key = result.__typename || 'unknown';
    const group = groupedResults[key];
    groupedResults[key] = group ? [...group, result] : [result];
  });

  // Filter out empty groups
  Object.keys(groupedResults).forEach((key) => {
    if (groupedResults[key].length === 0) {
      delete groupedResults[key];
    }
  });

  return groupedResults;
}

interface IProfileNameTypeaheadResultProps extends ItemProps {
  option: IProfileNameTypeaheadOption;
}

const _ProfileNameTypeaheadResult = ({ option, ...props }: IProfileNameTypeaheadResultProps) => {
  return (
    <_Result {...props} disabled={option.disabled} option={option}>
      <ProfileNameTypeaheadItem {...option} />
    </_Result>
  );
};

interface IRenderResultsProps {
  results: IProfileNameTypeaheadOption[];
}

const _renderGroupedResults = ({ results }: IRenderResultsProps) => {
  let position = 0;
  const groupedResults = groupResultsByType(results, ['Account', 'Team']);
  return Object.keys(groupedResults).map((type) => (
    <React.Fragment key={type}>
      <Dropdown.Header>{getHeaderLabelFromType(type)}</Dropdown.Header>
      {groupedResults[type].map((option) => (
        <_ProfileNameTypeaheadResult key={option.id} option={option} position={position++} />
      ))}
    </React.Fragment>
  ));
};

const _renderFlattenedResults = ({ results }: IRenderResultsProps) => {
  let position = 0;
  return results.map((option) => <_ProfileNameTypeaheadResult key={option.id} option={option} position={position++} />);
};

export interface IBaseProfileNameTypeaheadProps extends Omit<TypeaheadProps, 'onChange'> {
  flattenResults?: boolean;
  onChange?: (selected: IProfileNameTypeaheadOption[]) => void;
  options: IProfileNameTypeaheadOption[];
  selected?: IProfileNameTypeaheadOption[];
}

const BaseProfileNameTypeahead = React.forwardRef<TypeaheadRef, IBaseProfileNameTypeaheadProps>(
  ({ labelKey = 'name', ...props }, ref) => {
    return (
      <Typeahead
        {...props}
        labelKey={labelKey}
        onChange={(selected) => props.onChange?.(selected as IProfileNameTypeaheadOption[])}
        ref={ref}
        renderMenu={(results, { renderMenuItemChildren, ...menuProps }, childProps) => (
          <Typeahead.Menu {...menuProps}>
            {props.flattenResults
              ? _renderFlattenedResults({
                  results: results as IProfileNameTypeaheadOption[],
                })
              : _renderGroupedResults({
                  results: results as IProfileNameTypeaheadOption[],
                })}
          </Typeahead.Menu>
        )}
        renderToken={(option, props) => {
          const { disabled, id, name } = option as IProfileNameTypeaheadOption;
          return (
            <Typeahead.Token key={id} disabled={disabled || props.disabled} onRemove={props.onRemove} option={option}>
              {name}
            </Typeahead.Token>
          );
        }}
      />
    );
  }
);

BaseProfileNameTypeahead.displayName = 'BaseProfileTypeahead';

export { BaseProfileNameTypeahead };

interface IProfileNameTypeaheadProps extends Omit<IBaseProfileNameTypeaheadProps, 'options' | 'selected'> {
  enableNullQuery?: boolean;
  excludeIds?: string[];
  profileTypes?: TypeaheadProfileQueryProfileType[];
  selected?: IProfileNameTypeaheadOption[];
}

function ProfileNameTypeahead({
  enableNullQuery,
  excludeIds = [],
  minLength = 1,
  profileTypes,
  ...props
}: IProfileNameTypeaheadProps) {
  const [query, setQuery] = React.useState('');

  const { loading, typeaheadOptions } = useProfileNameTypeaheadOptionsQuery({
    enableNullQuery,
    profileTypes,
    query,
    selectedIds: props.selected?.map((option) => option.id) || [],
  });

  return (
    <BaseProfileNameTypeahead
      {...props}
      filterBy={() => true} // bypass default filtering (search back-end handles filtering)
      isLoading={loading || props.isLoading}
      minLength={minLength}
      onChange={(selected) => {
        props.onChange?.(selected);
        setQuery('');
      }}
      onInputChange={(query) => setQuery(query)}
      options={typeaheadOptions.filter(
        (profile) => !excludeIds.includes(profile.id) && !props.selected?.find((v) => v.id === profile.id)
      )}
    />
  );
}

export default ProfileNameTypeahead;
