import * as React from 'react';
import styled from 'styled-components';

const _ExternalBadge = styled.span`
  border: 1px solid ${({ theme }) => theme.color.variant.warning.border};
  border-radius: 4px;
  color: ${({ theme }) => theme.color.variant.warning.color};
  cursor: default;
  font-size: ${({ theme }) => theme.fontSize.xxxSmall};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  line-height: 16px;
  padding: 0 8px;
`;

export interface IExternalBadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  orgName?: string | null;
}

function ExternalBadge({ orgName, ...props }: IExternalBadgeProps) {
  return <_ExternalBadge {...props}>{orgName ?? 'External'}</_ExternalBadge>;
}

export default ExternalBadge;
