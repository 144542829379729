import * as React from 'react';

import Button from '../../ui/Button';
import Modal, { ModalProps } from '../Modal';

export interface IConfirmModalProps extends ModalProps {
  /**
   * The main message or content to display in the modal.
   */
  children: React.ReactNode;
  /**
   * If true, the cancel button will be disabled.
   */
  cancelDisabled?: boolean;
  /**
   * If true, the confirm button will be disabled.
   */
  confirmDisabled?: boolean;
  /**
   * The name of the action being taken, displayed in the confirm button.
   * Example: "Delete", "Leave", etc.
   */
  confirmLabel: string | JSX.Element;
  /**
   * If the action being taken is destructive (eg: deleting an object), display
   * the confirmation button using the `danger` variant for extra emphasis.
   */
  isDestructive?: boolean;
  onConfirm: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Title displayed in the modal header.
   */
  title: React.ReactNode;
}

/**
 * A simple wrapper around `Modal` for use when confirming an action, especially
 * one that cannot be reversed (eg: deletion).
 */
function ConfirmModal({
  children,
  cancelDisabled,
  confirmDisabled,
  confirmLabel,
  isDestructive,
  onConfirm,
  title,
  ...props
}: IConfirmModalProps) {
  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button autoFocus={isDestructive} disabled={cancelDisabled} onClick={props.onHide}>
          Cancel
        </Button>
        <Button
          autoFocus={!isDestructive}
          disabled={confirmDisabled}
          onClick={onConfirm}
          type="primary"
          variant={isDestructive ? 'danger' : undefined}
        >
          {confirmLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
