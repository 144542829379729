/**
 * Legacy Chat++ Palette
 * @deprecated
 */
const LegacyPalette = {
  brand1: '#04B8D0',

  blue100: '#006299',
  blue90: '#0176B8',
  blue80: '#0594E4',
  blue70: '#1BA5F2',
  blue60: '#3EBAFF',
  blue50: '#57C3FF',
  blue40: '#74CDFF',
  blue30: '#A3DEFF',
  blue20: '#C9ECFF',
  blue10: '#E2F5FF',

  black: '#000000',
  gray100: '#000000',
  gray90: '#1F2627',
  gray85: '#2A3336',
  gray80: '#384346',
  gray70: '#506265',
  gray60: '#687F84',
  gray50: '#8DA5AA',
  gray40: '#AEC1C5',
  gray30: '#CBD9DC',
  gray20: '#DEE9EC',
  gray10: '#EEF2F2',
  gray5: '#f9fafa',
  gray0: '#FFFFFF',
  white: '#ffffff',

  green100: '#309600',
  green80: '#4CA622',
  green60: '#56BC26',
  green40: '#9CD681',
  green20: '#BAE3A7',
  green10: '#e4f2de',
  greenText: '#28690b',

  orange100: '#C06902',
  orange80: '#D97602',
  orange60: '#F98803',
  orange40: '#F8B15F',
  orange20: '#FFD099',
  orange10: '#fde0c0',
  orange5: '#fff3e6',

  purple100: '#3B38B1',
  purple80: '#5552CA',
  purple60: '#7674E2',
  purple40: '#A7A5F3',
  purple20: '#C5C4F2',
  purple10: '#d8d7f9',

  red100: '#BA3113',
  red80: '#D4482A',
  red60: '#F15330',
  red40: '#F2907A',
  red20: '#FEC2B5',
  red10: '#fad9d3',

  teal100: '#098374',
  teal80: '#0C9C8B',
  teal60: '#03AF9B',
  teal40: '#55D1C2',
  teal20: '#A3E0D9',
  teal10: '#beefe9',

  violet100: '#A5174A',
  violet80: '#C5245E',
  violet60: '#DE427A',
  violet40: '#F58EB3',
  violet20: '#FEC4D9',

  yellow100: '#B69904',
  yellow80: '#C5A70E',
  yellow60: '#DCB904',
  yellow40: '#EBD250',
  yellow20: '#ECDE94',
  yellow10: '#fbf6db',
  yellowText: '#826e09',
};

export default LegacyPalette;
