import type * as Sentry from '@sentry/react';
import { Breadcrumb } from '@sentry/types';

import { IS_DESKTOP_APP, IS_MOBILE_APP } from '../../env';

const sentryDSNs = require('../../../../../sentryDSNs.json');

const IS_DEV = process.env['NODE_ENV'] !== 'production';

export default class SentryAgent {
  private static _getDSN(): string {
    if (IS_DESKTOP_APP) {
      return IS_DEV ? sentryDSNs['c-desktop-sb'] : sentryDSNs['c-desktop'];
    } else if (IS_MOBILE_APP) {
      return IS_DEV ? sentryDSNs['c-mobile-sb'] : sentryDSNs['c-mobile'];
    } else {
      return IS_DEV ? sentryDSNs['c-webapp-sb'] : sentryDSNs['c-webapp'];
    }
  }
  static getInstance(): typeof Sentry {
    // Use different sentry package depending on whether we're operating in the browser vs electron context
    if (IS_DESKTOP_APP) {
      return require('@sentry/electron/renderer');
    } else {
      return require('@sentry/react');
    }
  }

  static init() {
    !IS_DEV && // Disable in dev by default for now to avoid needlessly burning Sentry quota
      this.getInstance().init({
        dsn: this._getDSN(),
        environment: IS_DEV ? `sb-${process.env.USER}` : process.env.RMVW_ENV,
        release: process.env.GIT_HASH,
        ignoreErrors: [
          // Ignore errors that are not actionable
          'ResizeObserver loop limit exceeded', // benign warning
        ],
      });
  }

  static addBreadcrumb(crumb: Breadcrumb) {
    this.getInstance().addBreadcrumb(crumb);
  }
}
