import * as React from 'react';
import styled from 'styled-components';

import { hexColorAdjustBrightness } from '../../lib/css';

const _UnreadIndicator = styled.div<{ color?: string; size?: number }>`
  aspect-ratio: 1;
  background: radial-gradient(
    circle at 65% 15%,
    ${({ theme, color }) => hexColorAdjustBrightness(color ?? theme.color.variant.primary.color, 1.0)} 10%,
    ${({ theme, color }) => hexColorAdjustBrightness(color ?? theme.color.variant.primary.color, 0.8)} 60%,
    ${({ theme, color }) => hexColorAdjustBrightness(color ?? theme.color.variant.primary.color, 0.2)} 100%
  );
  border-radius: 50%;
  height: ${({ size }) => (size ? `${size}px` : 'auto')};
  min-height: ${({ size }) => (size ? `${size}px` : undefined)};
`;

export interface IUnreadIndicatorProps {
  color?: string;
  size?: number;
}

function UnreadIndicator(props: IUnreadIndicatorProps) {
  return <_UnreadIndicator color={props.color} size={props.size} />;
}

export default UnreadIndicator;
