import { YDocSyncMessage } from '@rmvw/x-common';
import { Observable } from 'lib0/observable';

import Logger from '../observability/Logger';

type YDocBCEventListener = (msg: YDocSyncMessage) => void;

const BROADCAST_CHANNEL_NAME = '_YDoc';

/**
 * BroadcastChannel transport
 */
export default class YDocBCTransport extends Observable<'connect'> {
  private _bc?: BroadcastChannel;
  private _listeners: Map<string, Set<YDocBCEventListener>> = new Map();
  private _msgQueue: YDocSyncMessage[] = [];

  private _bcErrorEventListener = (event: Event) => {
    Logger.error(event, '[YDocBCTransport]');
  };

  private _bcMessageEventListener = (event: MessageEvent<YDocSyncMessage>) => {
    const { data } = event;
    const { docId } = data;

    const listeners = this._listeners.get(docId);
    if (!listeners) {
      return;
    }

    listeners.forEach((listener) => {
      try {
        listener(data);
      } catch (e) {
        Logger.error(e as Error, '[YDocBCTransport] Listener error');
      }
    });
  };

  constructor() {
    super();
  }

  broadcast(msg: YDocSyncMessage) {
    if (!this._bc) {
      this._msgQueue.push(msg); // BC connection is not yet active: queue message
    } else {
      this._bc?.postMessage(msg);
    }
  }

  addEventListener(docId: string, listener: YDocBCEventListener): this {
    // Implicitly connect
    this.connect();
    this._listeners.set(docId, (this._listeners.get(docId) ?? new Set()).add(listener));
    return this;
  }

  removeEventListener(docId: string, listener: YDocBCEventListener): this {
    this._listeners.get(docId)?.delete(listener);
    if (this._listeners.get(docId)?.size === 0) {
      this._listeners.delete(docId);
    }

    // Implicitly disconnect if there are no more active listeners
    if (this._listeners.size === 0) {
      this.disconnect();
    }

    return this;
  }

  connect() {
    if (this._bc) {
      return;
    }

    try {
      this._bc = new BroadcastChannel(BROADCAST_CHANNEL_NAME);
      this._bc.addEventListener('message', this._bcMessageEventListener);
      this._bc.addEventListener('messageerror', this._bcErrorEventListener);

      // Flush any queued messages
      this._msgQueue.forEach((msg) => this.broadcast(msg));
      this._msgQueue = [];

      // Finally fire connected event
      this.emit('connect', []);
    } catch (e) {
      Logger.error(e as Error, '[YDocBCTransport] Connect error');
      this._bc = undefined;
    }
  }

  disconnect() {
    if (!this._bc) {
      return;
    }
    this._bc.removeEventListener('messageerror', this._bcErrorEventListener);
    this._bc.removeEventListener('message', this._bcMessageEventListener);
    this._bc = undefined;
    this._listeners.clear();
  }
}
