import { IRTElementNode, RTElementProp } from '@rmvw/x-common';
import * as React from 'react';
import { useSlateStatic } from 'slate-react';

import { IResolverStatus, RTEditor } from '../../../../lib/rich-text/Editor';

export interface IUseResolverStatusProps {
  element: IRTElementNode;
}

export interface IUseResolverStatusResult {
  previewUrl?: string;
  status?: IResolverStatus;
}

export function useResolverStatus(props: IUseResolverStatusProps): IUseResolverStatusResult {
  const editor = useSlateStatic() as RTEditor;

  // Make resolver status sticky for component lifetime to simplify state transitions between resolving and resolved;
  const [status] = React.useState((): IResolverStatus | undefined => {
    const resolverId = props.element[RTElementProp.__RESOLVER_ID];
    if (!resolverId) {
      return undefined;
    }
    // Fallback to generic error if resolver is not found
    return editor.getResolverStatus(resolverId) ?? { error: new Error('Upload failed'), getProgress: () => 0 };
  });

  const previewUrl = React.useMemo(
    () => (status?.data?.file ? URL.createObjectURL(status.data.file) : undefined),
    [status?.data?.file]
  );

  return { previewUrl, status };
}
