import { gql, useQuery } from '@apollo/client';
import * as React from 'react';

import { TypeaheadProfileQueryFilterInputType } from '../../../___generated___/globalTypes';
import { ProfileAvatar } from '../../Avatar';

import {
  CF_SearchTokenOptionsQueryProfile,
  CF_SearchTypeaheadOptionsQueryProfile,
  SearchTypeaheadOptionsQuery_Typeahead,
  SearchTypeaheadOptionsQuery_TypeaheadVariables,
} from './___generated___/useSearchTypeaheadOptionsQuery.types';

const DEFAULT_AVATAR_SIZE = 32;

export type ISearchTypeaheadOption = CF_SearchTypeaheadOptionsQueryProfile & {
  disabled?: boolean;
  searchMetadata?: {
    highlights: { name: string | null; highlights: readonly string[] } | null;
    score: number | null;
  };
};

export type ISearchTokenOption = CF_SearchTokenOptionsQueryProfile & {
  disabled?: boolean;
  searchMetadata?: {
    highlights: { name: string | null } | null;
    score: number | null;
  };
};

interface IUseSearchTypeaheadOptionsQueryProps {
  avatarSize?: number;
  enableNullQuery?: boolean;
  filters?: TypeaheadProfileQueryFilterInputType;
  limit?: number;
  matchingParticipantIds?: string[];
  query: string;
  tokenSuggestionLimit?: number;
}

export function useSearchTypeaheadOptionsQuery({
  avatarSize = DEFAULT_AVATAR_SIZE,
  query,
  ...props
}: IUseSearchTypeaheadOptionsQueryProps) {
  const typeaheadOptions = React.useRef<ISearchTypeaheadOption[]>([]);
  const tokenOptions = React.useRef<ISearchTokenOption[]>([]);

  const { data, ...results } = useQuery<
    SearchTypeaheadOptionsQuery_Typeahead,
    SearchTypeaheadOptionsQuery_TypeaheadVariables
  >(SearchTypeaheadOptionsQuery, {
    fetchPolicy: 'cache-and-network', // balance of speed and freshness
    skip: !query && !props.enableNullQuery,
    variables: {
      filters: props.filters,
      limit: props.limit,
      matchingParticipantIds: props.matchingParticipantIds,
      query,
      tokenSuggestionLimit: props.tokenSuggestionLimit,
    },
  });

  if (!results.loading) {
    // Use ref to avoid render flicker when options change.
    typeaheadOptions.current =
      data?.searchTypeaheadQuery
        ?.map((result) =>
          result?.profile
            ? { ...result?.profile, searchMetadata: { highlights: result.highlights, score: result.score } }
            : null
        )
        .filterNullish() ?? [];

    tokenOptions.current =
      data?.profileNameTypeaheadQuery
        ?.map((result) =>
          result?.profile
            ? { ...result?.profile, searchMetadata: { highlights: result.highlights, score: result.score } }
            : null
        )
        .filterNullish() ?? [];
  }

  return {
    ...results,
    tokenOptions: tokenOptions.current,
    typeaheadOptions: typeaheadOptions.current,
  };
}

useSearchTypeaheadOptionsQuery.fragment = gql`
  ${ProfileAvatar.fragment}
  fragment CF_SearchTypeaheadOptionsQueryProfile on IProfile {
    id
    org {
      id
      name
    }
    permalink
    shortcuts {
      id
    }

    ...CF_ProfileAvatar

    ... on IThread {
      createdAt
      lastEventAt
      lastVisitedAt
    }

    ... on Account {
      privateChat {
        id
        createdAt
        lastEventAt
        lastVisitedAt
      }
    }

    ... on Discussion {
      audience
    }

    ... on Team {
      audience
      createdAt
      lastVisitedAt
      lastEventAt
    }
  }
`;

const SearchTokenOptionsQueryProfile = gql`
  fragment CF_SearchTokenOptionsQueryProfile on IProfile {
    id
    name
    ... on Account {
      givenName
    }
  }
`;

export const SearchTypeaheadOptionsQuery = gql`
  ${useSearchTypeaheadOptionsQuery.fragment}
  ${SearchTokenOptionsQueryProfile}
  query SearchTypeaheadOptionsQuery_Typeahead(
    $filters: TypeaheadProfileQueryFilterInputType
    $limit: Int
    $matchingParticipantIds: [String!]
    $query: String!
    $tokenSuggestionLimit: Int
  ) {
    # Core search query
    searchTypeaheadQuery(
      filters: $filters
      limit: $limit
      matchingParticipantIds: $matchingParticipantIds
      query: $query
    ) {
      profile {
        ...CF_SearchTypeaheadOptionsQueryProfile
      }

      highlights {
        name
        highlights
      }

      score
    }

    # Token query
    profileNameTypeaheadQuery(filters: { profileTypes: [ACCOUNT] }, limit: $tokenSuggestionLimit, query: $query) {
      profile {
        ...CF_SearchTokenOptionsQueryProfile
      }

      highlights {
        name
      }

      score
    }
  }
`;
