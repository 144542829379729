import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import GlobalStyles from './GlobalStyles';
import themes, { ThemeName } from './themes';

interface IThemeProviderProps {
  children: React.ReactNode;
  theme: ThemeName;
}

export default function AppThemeProvider({ children, theme }: IThemeProviderProps) {
  const selectedTheme: ThemeName = theme;

  return (
    <ThemeProvider theme={themes[selectedTheme]}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
}
