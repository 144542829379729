import { useLocalStorage } from '@rehooks/local-storage';

const SHOW_APP_NAV = 'show-app-nav';

/**
 * Manages visibility of the main nav drawer.
 */
function useAppNavState() {
  const [showAppNav, setShowAppNav] = useLocalStorage<boolean>(SHOW_APP_NAV);

  function toggleAppNavState() {
    setShowAppNav(!showAppNav);
  }

  return [showAppNav, toggleAppNavState] as const;
}

export default useAppNavState;
