import * as React from 'react';
import { ToastContextType } from 'react-bootstrap/ToastContext';

export type ToastVariant = 'danger' | 'success' | 'warning';

export interface IToastContextType extends ToastContextType {
  variant?: ToastVariant;
}

const ToastContext = React.createContext<IToastContextType>({});

export default ToastContext;
