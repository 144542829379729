import * as React from 'react';

import Dropdown, { DropdownMenuProps, DropdownProps, IDropdownToggleProps } from '../Dropdown';

type PropsFromToggle = Pick<
  IDropdownToggleProps,
  'disabled' | 'hideCaret' | 'icon' | 'iconWidth' | 'href' | 'id' | 'shape' | 'size' | 'type'
>;
type PropsFromDropdown = Omit<DropdownProps, 'title'>;
type PropsFromMenu = Pick<DropdownMenuProps, 'flip' | 'popperConfig' | 'rootCloseEvent'>;

export interface IDropdownButtonProps extends PropsFromDropdown, PropsFromToggle, PropsFromMenu {
  label?: React.ReactNode;
  menuRole?: string;
  renderMenuOnMount?: boolean;
  title?: string;
}

/**
 * A convenience component for simple or general-use dropdowns. Renders a
 * `Button` toggle where all children are passed directly to the default
 * `Dropdown.Menu`. This component accepts all of `Dropdown`'s props.
 */
const DropdownButton = React.forwardRef<HTMLDivElement, IDropdownButtonProps>(
  (
    {
      children,
      disabled,
      flip,
      hideCaret,
      href,
      icon,
      iconWidth,
      id,
      menuRole,
      popperConfig,
      renderMenuOnMount,
      rootCloseEvent,
      shape,
      size,
      label,
      title,
      type,
      ...props
    },
    ref
  ) => (
    <Dropdown ref={ref} {...props}>
      <Dropdown.Toggle
        disabled={disabled}
        hideCaret={hideCaret}
        href={href}
        icon={icon}
        iconWidth={iconWidth}
        id={id}
        shape={shape}
        size={size}
        title={title}
        type={type}
      >
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu
        popperConfig={popperConfig}
        renderOnMount={renderMenuOnMount}
        role={menuRole}
        rootCloseEvent={rootCloseEvent}
        flip={flip}
      >
        {children}
      </Dropdown.Menu>
    </Dropdown>
  )
);

DropdownButton.displayName = 'DropdownButton';

export default DropdownButton;
