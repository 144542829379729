import { DiscussionIconType, normalizeDiscussionIconType } from '@rmvw/x-common';
import * as React from 'react';

import { CanonicalSize } from '../../../providers/ThemeProvider/themes';
import {
  AnnouncementIcon,
  CameraIcon,
  DiscussionIcon,
  LightbulbIcon,
  MailIcon,
  MessageAlertIcon,
  MessageQuestionIcon,
  TagIcon,
  TargetIcon,
  TeamIcon,
  UmbrellaIcon,
} from '../../Icon';

export interface IDiscussionProfileIconProps {
  size?: CanonicalSize | number;
  strokeWidth?: number;
  type: string | null;
}

function DiscussionProfileIcon({ size, strokeWidth, type }: IDiscussionProfileIconProps) {
  const iconType = normalizeDiscussionIconType(type ?? '');
  switch (iconType) {
    case DiscussionIconType.ANNOUNCEMENT:
      return <AnnouncementIcon size={size} strokeWidth={strokeWidth} />;
    case DiscussionIconType.DEFAULT:
      return <DiscussionIcon size={size} strokeWidth={strokeWidth} />;
    case DiscussionIconType.IDEA:
      return <LightbulbIcon size={size} strokeWidth={strokeWidth} />;
    case DiscussionIconType.IMPORTANT:
      return <MessageAlertIcon size={size} strokeWidth={strokeWidth} />;
    case DiscussionIconType.ISSUE:
      return <TargetIcon size={size} strokeWidth={strokeWidth} />;
    case DiscussionIconType.MAIL:
      return <MailIcon size={size} strokeWidth={strokeWidth} />;
    case DiscussionIconType.MEETING:
      return <CameraIcon size={size} strokeWidth={strokeWidth} />;
    case DiscussionIconType.QUESTION:
      return <MessageQuestionIcon size={size} strokeWidth={strokeWidth} />;
    case DiscussionIconType.RANDOM:
      return <UmbrellaIcon size={size} strokeWidth={strokeWidth} />;
    case DiscussionIconType.TAG:
      return <TagIcon size={size} strokeWidth={strokeWidth} />;
    case DiscussionIconType.TEAM:
      return <TeamIcon size={size} strokeWidth={strokeWidth} />;
  }
}

export default DiscussionProfileIcon;
