import * as React from 'react';
import {
  Highlighter,
  Typeahead as RBTypeahead,
  RenderMenuProps,
  Token,
  TypeaheadComponentProps,
  TypeaheadMenuProps,
  TypeaheadRef,
  UseItemProps,
  useItem,
} from 'react-bootstrap-typeahead';
import type { LabelKey, Option, TypeaheadManagerChildProps } from 'react-bootstrap-typeahead/types/types';
import styled from 'styled-components';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

import Dropdown, { IDropdownItemProps } from '../Dropdown';
import { focusFormStyles, formStyles } from '../Form';

export type { TypeaheadRef };
export type TypeaheadProps = TypeaheadComponentProps;

export type ItemProps = IDropdownItemProps & UseItemProps<HTMLDivElement>;

function Item(props: ItemProps) {
  const { as, ...itemProps } = useItem(props);
  return <Dropdown.Item {...itemProps} />;
}

export type MenuProps = Omit<TypeaheadMenuProps, 'options' | 'text' | 'labelKey' | 'renderMenuItemChildren'>;

function Menu({
  // Omit to avoid TS & console errors.
  as,
  newSelectionPrefix,
  paginationText,

  // Used
  emptyLabel = 'No matches found.',
  innerRef,
  maxHeight = '300px',
  style,
  width,
  ...props
}: MenuProps) {
  // Empty state when there are no results.
  const children = !React.Children.count(props.children) ? (
    <Dropdown.Item disabled role="option">
      {emptyLabel}
    </Dropdown.Item>
  ) : (
    props.children
  );

  return (
    <Dropdown.Menu
      {...props}
      ref={innerRef}
      role="listbox"
      show={true}
      style={{
        ...style,
        maxHeight,
        overflow: 'auto',
        width: width ?? style?.width,
      }}
    >
      {children}
    </Dropdown.Menu>
  );
}

function getOptionLabel(option: Option, labelKey: LabelKey) {
  if (typeof option === 'string') {
    return option;
  }
  return typeof labelKey === 'function' ? labelKey(option) : option[labelKey];
}

function defaultRenderMenuItemChildren(option: Option, props: TypeaheadMenuProps) {
  return (
    // Wrap string with `span` so whitespace doesn't collapse due to
    // `display: flex;` on `Dropdown.Item`.
    <span>
      <Highlighter search={props.text}>{getOptionLabel(option, props.labelKey)}</Highlighter>
    </span>
  );
}

function defaultRenderMenu(
  results: Option[],
  { renderMenuItemChildren = defaultRenderMenuItemChildren, ...menuProps }: RenderMenuProps,
  props: TypeaheadManagerChildProps
) {
  const childProps = {
    labelKey: props.labelKey,
    options: results,
    renderMenuItemChildren,
    text: props.text,
  };

  return (
    <Menu {...menuProps}>
      {results.map((option, idx) => (
        <Item key={idx} option={option} position={idx}>
          {renderMenuItemChildren(option, childProps, idx)}
        </Item>
      ))}
    </Menu>
  );
}

const _RBTypeahead = styled(RBTypeahead)<{ $size?: 'sm' | 'lg' }>`
  & .form-control {
    ${formStyles}
    align-items: center;
    display: flex;
    height: unset;

    ${({ $size }) => {
      if ($size === 'sm') {
        return `
        height: 28px;
        padding-bottom: .5px;
        padding-top: .5px;
      `;
      }

      if ($size === 'lg') {
        return `
        height: 48px;
        padding-bottom: 10.5px;
        padding-top: 10.5px;
      `;
      }

      return `
      height: 40px;
      padding-bottom: 6.5px;
      padding-top: 6.5px;
    `;
    }}
  }

  & .focus {
    ${focusFormStyles}
    &:hover {
      border-color: ${({ theme }) => theme.color.emphasisColor} !important;
    }
  }

  & .disabled {
    cursor: default;

    &:hover {
      border-color: ${({ theme }) => theme.color.secondaryBorder} !important;
    }
  }

  & .rbt-input-main,
  & .rbt-input-multi {
    color: ${({ theme }) => theme.color.color};
    margin-bottom: 0;
    margin-top: 0;
  }

  & .rbt-input-wrapper {
    align-items: center;
    display: flex;
    margin-bottom: 0;
    margin-top: 0;
    width: 100%;
  }

  & .rbt-input-hint {
    color: ${({ theme }) => theme.color.secondaryColor} !important;
  }

  & .rbt-token {
    align-items: center;
    background-color: ${({ theme }) => theme.color.background200};
    color: ${({ theme }) => theme.color.color};
    display: flex;
    margin: 2px 3px 2px 0;
  }

  & .rbt-token-remove-button {
    align-items: center;
  }

  & .rbt-input-main.disabled,
  & .rbt-input-multi.disabled {
    background-color: ${({ theme }) => theme.color.background400};
  }

  & .rbt-loader {
    border-style: dotted;
    border-width: 1.5px;
    color: ${({ theme }) => theme.color.color};
    opacity: 0.66;
  }
`;

const Typeahead = React.forwardRef<TypeaheadRef, TypeaheadProps>((props, ref) => {
  return <_RBTypeahead {...props} $size={props.size} ref={ref} renderMenu={props.renderMenu || defaultRenderMenu} />;
});

Typeahead.displayName = 'Typeahead';

export default Object.assign(Typeahead, {
  Highlighter,
  Item,
  Menu,
  Token,
});
