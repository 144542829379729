import * as React from 'react';
import { useTheme } from 'styled-components';

import { IIconWrapperProps } from '../../icons/untitled-ui/UntitledUIIconWrapper';
import { FileAttachmentIcon } from '../Icon';

export function useFileUploadIconOptions() {
  // TODO: support file type specific icons
  const options: Map<string, { contentType: string; icon: React.ComponentType<IIconWrapperProps>; iconColor: string }> =
    new Map();

  return options;
}

function useIconProps(contentType?: string, extension?: string) {
  const options = useFileUploadIconOptions();
  const theme = useTheme();

  if (contentType) {
    const values = [...options.values()];
    const option = values.find((o) => o.contentType === contentType);
    if (option) {
      return {
        Icon: option.icon,
        iconColor: option.iconColor,
      };
    }
  }

  if (extension) {
    const option = options.get(extension.toLowerCase());
    if (option) {
      return {
        Icon: option.icon,
        iconColor: option.iconColor,
      };
    }
  }

  // Default
  return {
    Icon: FileAttachmentIcon,
    iconColor: theme.color.lightTeal,
  };
}

export interface IFileUploadIconProps extends IIconWrapperProps {
  contentType?: string;
  extension?: string;
}

function FileUploadIcon({ contentType, extension, ...props }: IFileUploadIconProps) {
  const { Icon, iconColor } = useIconProps(contentType, extension);

  return <Icon {...props} color={iconColor} />;
}

export default FileUploadIcon;
