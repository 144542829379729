import * as React from 'react';
import FormContext from 'react-bootstrap/FormContext';

// `FormContextType` isn't exported from React-Bootstrap, so re-declare it
// here to keep TS happy.
export interface IFormContextType {
  controlId?: string;
}

export default function useFormContext() {
  return React.useContext<IFormContextType>(FormContext);
}
