import * as React from 'react';
import Nav from 'react-bootstrap/Nav';
import { NavLinkProps } from 'react-bootstrap/NavLink';
import styled, { css } from 'styled-components';

import useAppNavState from '../../../hooks/useAppNavState';
import useBreakpoints from '../../../hooks/useBreakpoints';
import { truncateText } from '../../../lib/css';
import CloseButton from '../../CloseButton';
import Count from '../../Count';
import Highlight from '../../Highlight';
import LiveVideoBadge from '../../LiveVideoBadge';
import UnreadIndicator from '../../UnreadIndicator';

export interface INavBarItemProps extends NavLinkProps {
  highlight?: boolean;
  icon?: JSX.Element;
  isLive?: boolean;
  onRemove?: () => void;
  showUnreadCount?: boolean;
  unreadCount?: number;
}

const NavItemUnread = styled.div`
  align-items: center;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 16px;
`;

export const NavItemIcon = styled.div`
  color: ${({ theme }) => theme.color.secondaryColor};
  display: flex;
`;

export const NavItemLabel = styled.div<{ $unreadCount: boolean }>`
  align-items: center;
  color: ${({ theme }) => theme.color.color};
  flex: 1;
  ${({ theme, $unreadCount }) => $unreadCount && `font-weight: ${theme.fontWeight.bold};`}
  ${truncateText()}
`;

const _LiveVideoBadge = styled(LiveVideoBadge)``;
const _Count = styled(Count)``;
const _CloseButton = styled(CloseButton)`
  display: none;
`;

const navRowStyles = css`
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  display: flex;
  gap: 8px;
  height: 32px;
  padding: 0 8px;
  width: 100%;
`;

const _NavLink = styled(Nav.Link)<{ $highlight?: boolean }>`
  ${navRowStyles}
  color: ${({ theme }) => theme.color.color};
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  margin: 0 0 2px;
  padding: ${({ $highlight }) => ($highlight ? '0' : '0 8px')};

  .nav-pills &&.active {
    background-color: ${({ theme }) => theme.color.background500};
    color: ${({ theme }) => theme.color.color};
  }

  &:active,
  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.color.background500};
    color: ${({ theme }) => theme.color.color};

    ${_CloseButton} {
      display: flex;
    }

    /* Hide the live video badge and unread count when the close button is visible */
    ${_CloseButton} ~ ${_LiveVideoBadge}, ${_CloseButton} ~ ${_Count} {
      display: none;
    }
  }
`;

export const NavBarItemHighlight = styled(Highlight)`
  ${navRowStyles}
`;

const NavBarItem = React.forwardRef<HTMLDivElement, INavBarItemProps>(
  ({ children, highlight, icon, isLive, onRemove, showUnreadCount, unreadCount, ...props }, ref) => {
    const { isDesktopSize, isMobileSize } = useBreakpoints();
    const [_, toggleShowAppNav] = useAppNavState();

    return (
      <Nav.Item ref={ref}>
        <_NavLink
          $highlight={highlight}
          {...props}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            // Hide the nav when menu items are clicked at smaller screen sizes.
            if (!isDesktopSize) {
              toggleShowAppNav();
            }
            props.onClick?.(e);
          }}
        >
          <NavBarItemHighlight delay={0} highlight={highlight}>
            {!isMobileSize && <NavItemUnread>{!!unreadCount && <UnreadIndicator size={6} />}</NavItemUnread>}
            {icon && <NavItemIcon>{icon}</NavItemIcon>}
            <NavItemLabel $unreadCount={!!unreadCount && isMobileSize}>{children}</NavItemLabel>
            {onRemove && (
              <_CloseButton
                onClick={(e) => {
                  // Stop click from selecting the NavBarItem.
                  e.stopPropagation();
                  onRemove();
                }}
                size="small"
              />
            )}
            {isLive && <_LiveVideoBadge invert />}
            {showUnreadCount && <_Count count={unreadCount} />}
          </NavBarItemHighlight>
        </_NavLink>
      </Nav.Item>
    );
  }
);

NavBarItem.displayName = 'NavBarItem';

export default NavBarItem;
