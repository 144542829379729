import {
  CheckCircleFillIcon,
  CheckIcon,
  GitMergeIcon,
  GitPullRequestClosedIcon,
  GitPullRequestDraftIcon,
  GitPullRequestIcon,
  HourglassIcon,
  XCircleIcon,
} from '@primer/styled-octicons';
import { IGithubPRData } from '@rmvw/x-common';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import * as React from 'react';
import ReactTimeAgo from 'react-time-ago';
import styled from 'styled-components';

import { lineClamp, truncateText } from '../../lib/css';
import Card from '../Card';
import MetadataBadge from '../MetadataBadge/MetadataBadge';
import Space from '../Space';

export type IGithubPRPreviewProps = {
  objectData: Partial<IGithubPRData>;
};

const ICON_SIZE = 16;
TimeAgo.addDefaultLocale(en);

export default function GithubPRPreview(props: IGithubPRPreviewProps) {
  const pr = props.objectData;

  function getStateDeclaration() {
    switch (pr.state) {
      case 'MERGED':
        return <_StateDeclaration verb="Merged" by={pr.mergedBy} at={pr.mergedAt} />;
      case 'CLOSED':
        return <_StateDeclaration verb="Closed" at={pr.closedAt} />;
      case 'OPEN':
        return <_StateDeclaration verb="Opened" by={pr.author} at={pr.createdAt} />;
      default:
        return null;
    }
  }

  return (
    <Card>
      <_Header>
        <_TitleLine>
          <a href={pr.permalink} target="_">{`(#${pr.number})`}</a>
          <_Title>{pr.title}</_Title>
          {!!pr.comments?.totalCount && (
            <MetadataBadge count={pr.comments.totalCount} type="replies" variant="secondary" />
          )}
        </_TitleLine>
        <_Subtitle>
          <_StateIcon state={pr.state} isDraft={pr.isDraft} />
          {getStateDeclaration()}
          <_StatusCheckIcon status={pr.commits?.nodes[0]?.commit?.statusCheckRollup?.state} />
        </_Subtitle>
      </_Header>
      <Card.Body>
        <_BodyText>{pr.bodyText}</_BodyText>
      </Card.Body>
    </Card>
  );
}

function _StateDeclaration(props: { verb: string; by?: { login: string; url: string } | null; at?: string | null }) {
  const by = props.by ? (
    <>
      {' by '}
      <a href={props.by.url} target="_">
        {props.by.login}
      </a>
    </>
  ) : null;

  const at = props.at ? (
    <>
      {' '}
      <ReactTimeAgo timeStyle={'round-minute'} local={'en-US'} date={new Date(props.at)} />
    </>
  ) : null;

  return (
    <span>
      {props.verb}
      {by}
      {at}
    </span>
  );
}

function _StatusCheckIcon(props: { status?: string }) {
  switch (props.status) {
    case 'ERROR':
      return <XCircleIcon size={ICON_SIZE} color="red" />;
    case 'EXPECTED':
      return <CheckIcon size={ICON_SIZE} />;
    case 'FAILURE':
      return <XCircleIcon size={ICON_SIZE} color="red" />;
    case 'PENDING':
      return <HourglassIcon size={ICON_SIZE} color="orange" />;
    case 'SUCCESS':
      return <CheckCircleFillIcon size={ICON_SIZE} color="green" />;
    default:
      return null;
  }
}

function _StateIcon(props: { state?: string; isDraft?: boolean }) {
  switch (props.state) {
    case 'MERGED':
      return <GitMergeIcon size={ICON_SIZE} color="purple" />;
    case 'CLOSED':
      return <GitPullRequestClosedIcon size={ICON_SIZE} color="red" />;
    case 'OPEN':
      return props.isDraft ? (
        <GitPullRequestDraftIcon size={ICON_SIZE} />
      ) : (
        <GitPullRequestIcon size={ICON_SIZE} color="green" />
      );
    default:
      return null;
  }
}

const _Header = styled(Card.Header)`
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  display: block;
`;

const _TitleLine = styled(Space).attrs({ size: 8 })`
  display: flex;
`;

const _Title = styled.div`
  flex: 1;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  ${truncateText()}
`;

const _Subtitle = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.secondaryColor};
  display: flex;
  gap: 8px;
`;

const _BodyText = styled.div`
  ${lineClamp(3)}
`;
