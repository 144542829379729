import * as React from 'react';
import { RenderElementProps } from 'slate-react';
import styled from 'styled-components';

const _InnerSpan = styled.span`
  user-select: none;
`;

export interface IVoidInlineElementProps
  extends Omit<RenderElementProps, 'children' | 'element'>,
    React.HTMLAttributes<HTMLSpanElement> {
  slateChildren: React.ReactNode;
}

export default function VoidInlineElement({
  attributes,
  slateChildren,
  ...props
}: React.PropsWithChildren<IVoidInlineElementProps>) {
  if (!attributes['data-slate-inline']) {
    throw new Error('This component should only be used for inline elements!');
  }

  //
  // IMPORTANT: DO NOT REMOVE THE SPAN WRAPPERS BELOW
  // SlateJS is EXTREMELY sensitive to the DOM structure of void elements it renders.
  //
  return (
    <span {...attributes} {...props}>
      <span contentEditable={false}>
        <_InnerSpan>{props.children}</_InnerSpan>
      </span>
      {slateChildren}
    </span>
  );
}
