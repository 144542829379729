import { gql } from '@apollo/client';
import { parseISO } from 'date-fns';
import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { ClockIcon, FilterIcon } from '../../Icon';
import ListFormat from '../../ListFormat';
import RichText from '../../rich-text/RichText';
import AssistantFilterTimeSpan from '../AssistantFilterTimeSpan';
import AssistantMessage, { IAssistantMessageActionProps } from '../AssistantMessage';

import { CF_AssistantUserMessage_UserTypeAssistantMessage } from './___generated___/AssistantUserMessage.types';

const _Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const _MessageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const _Message = styled.div`
  background-color: ${({ theme }) => theme.color.background400};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSize.normal};
  padding: 4px 12px;
`;

const _ContextFilters = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.secondaryColor};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xxSmall};
  gap: 4px;
  justify-content: flex-end;
  padding: 0 8px;
`;

const _Timespan = styled.div`
  display: flex;
  gap: 4px;
`;

export interface IAssistantUserMessageProps
  extends React.HTMLAttributes<HTMLDivElement>,
    Pick<IAssistantMessageActionProps, 'onCopyMessage'> {
  message: CF_AssistantUserMessage_UserTypeAssistantMessage;
}

function AssistantUserMessage({ message, onCopyMessage, ...divProps }: IAssistantUserMessageProps) {
  const theme = useTheme();

  return (
    <AssistantMessage justifyContent="end" onCopyMessage={onCopyMessage}>
      <_Wrapper>
        <_MessageContainer>
          <_Message {...divProps}>
            <RichText value={message.body} />
          </_Message>
        </_MessageContainer>
        {message.contextFilters.scopedThreads.length ? (
          <_ContextFilters>
            <FilterIcon color={theme.color.color} />
            <ListFormat
              items={message.contextFilters.scopedThreads.map(({ id, name }) => (
                <strong key={id}>{name}</strong>
              ))}
            />
          </_ContextFilters>
        ) : null}
        {message.contextFilters.startTime || message.contextFilters.endTime ? (
          <_ContextFilters>
            <ClockIcon color={theme.color.color} />
            <AssistantFilterTimeSpan
              startTime={message.contextFilters.startTime ? parseISO(message.contextFilters.startTime) : undefined}
              endTime={message.contextFilters.endTime ? parseISO(message.contextFilters.endTime) : undefined}
            />
          </_ContextFilters>
        ) : null}
      </_Wrapper>
    </AssistantMessage>
  );
}

AssistantUserMessage.fragment = gql`
  ${AssistantMessage.fragment}
  fragment CF_AssistantUserMessage on IAssistantMessage {
    ...CF_AssistantMessage
    ... on UserTypeAssistantMessage {
      __typename
    }
  }
`;

export default AssistantUserMessage;
