import * as React from 'react';

import Logger from '../../lib/observability/Logger';
import ErrorScene from '../../scenes/error/ErrorScene';

// Render the error scene by default
function defaultRenderError(error: Error) {
  return <ErrorScene error={error} />;
}

interface IErrorBoundaryProps {
  children: React.ReactNode;
  renderError?: (error: Error) => JSX.Element;
}

interface IErrorBoundaryState {
  error: Error | null;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  override state = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Logger.fatal(error, errorInfo.componentStack);
  }

  override render() {
    const { children, renderError = defaultRenderError } = this.props;
    return this.state.error ? renderError(this.state.error) : children;
  }
}

export default ErrorBoundary;
