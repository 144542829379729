import * as React from 'react';
import type { SVGProps } from 'react';
import IconWrapper, { IIconWrapperProps } from '../../IconWrapper';
function ClockRewind15OutlineIcon({
  className,
  role,
  size,
  ...props
}: IIconWrapperProps & Omit<SVGProps<SVGSVGElement>, 'height' | 'width' | 'strokeWidth'>) {
  return React.createElement(
    IconWrapper,
    {
      role,
      size,
      ...props,
    },
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, 'uiiIcon', 'uiIcon-ClockRewind15OutlineIcon'].filter((c) => !!c).join(' ')}
      {...props}
    >
      <path
        d="M14.35 2.15015L12.35 4.14955L14.35 6.15015M12.85 3.8501C17.8205 3.8501 21.85 7.87954 21.85 12.8501C21.85 17.8207 17.8205 21.8501 12.85 21.8501C7.87941 21.8501 3.84998 17.8207 3.84998 12.8501C3.84998 9.54818 5.62811 6.66157 8.27907 5.09559"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9929 16.263C14.5666 16.263 14.1864 16.1846 13.8526 16.0277C13.5207 15.8709 13.2572 15.6546 13.0621 15.3791C12.867 15.1036 12.7655 14.7878 12.7574 14.4318H14.0245C14.0386 14.6711 14.1392 14.8652 14.3262 15.0141C14.5133 15.1629 14.7355 15.2373 14.9929 15.2373C15.1981 15.2373 15.3791 15.1921 15.536 15.1015C15.6949 15.009 15.8186 14.8813 15.9071 14.7184C15.9976 14.5535 16.0428 14.3644 16.0428 14.1512C16.0428 13.934 15.9966 13.7429 15.904 13.578C15.8135 13.4131 15.6878 13.2844 15.5269 13.1919C15.366 13.0993 15.182 13.0521 14.9748 13.0501C14.7938 13.0501 14.6178 13.0873 14.4469 13.1617C14.2779 13.2361 14.1462 13.3377 14.0517 13.4664L12.8902 13.2582L13.1828 10H16.9599V11.068H14.2598L14.0999 12.6156H14.1361C14.2448 12.4628 14.4087 12.3361 14.6279 12.2355C14.8471 12.1349 15.0925 12.0847 15.364 12.0847C15.7361 12.0847 16.068 12.1722 16.3596 12.3471C16.6512 12.5221 16.8815 12.7625 17.0505 13.0682C17.2194 13.3719 17.3029 13.7218 17.3009 14.118C17.3029 14.5344 17.2063 14.9044 17.0112 15.2283C16.8182 15.5501 16.5476 15.8035 16.1997 15.9885C15.8538 16.1715 15.4515 16.263 14.9929 16.263Z"
        fill="currentColor"
      />
      <path d="M10.7574 10V16.1786H9.45112V11.2399H9.41492L8 12.1269V10.9684L9.52956 10H10.7574Z" fill="currentColor" />
    </svg>
  );
}
export default ClockRewind15OutlineIcon;
