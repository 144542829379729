import * as React from 'react';
import RBBadge from 'react-bootstrap/Badge';
import styled from 'styled-components';

import { IColorThemeVariants } from '../../providers/ThemeProvider/color-themes/IColorTheme';

export type BadgeVariant = keyof IColorThemeVariants;

const _Badge = styled(RBBadge)<IBadgeProps>`
  ${({ color, variant = 'primary', theme }) => {
    const bgColor = color || theme.color.variant[variant].color;
    return `background-color: ${bgColor} !important;`;
  }}

  border-radius: 2rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export interface IBadgeProps extends React.HTMLAttributes<HTMLSpanElement> {
  /**
   * Arbitrary color string that can be passed to set the background-color.
   * Will override `variant`.
   */
  color?: string;
  variant?: BadgeVariant;
}

const Badge = React.forwardRef<HTMLSpanElement, IBadgeProps>((props, ref) => {
  return <_Badge {...props} ref={ref} />;
});

Badge.displayName = 'Badge';

export default Badge;
