import * as React from 'react';
import Nav, { NavProps } from 'react-bootstrap/Nav';
import styled from 'styled-components';

import NavBarItem, { INavBarItemProps } from '../../NavBar/NavBarItem';
import NavBarSection, { INavBarSectionProps } from '../../NavBar/NavBarSection';

const _Nav = styled(Nav)`
  flex-flow: column nowrap;
`;

export type { INavBarItemProps, INavBarSectionProps };
export type INavBarProps = NavProps;

function NavBar(props: React.PropsWithChildren<INavBarProps>) {
  return (
    <_Nav {...props} variant="pills">
      {props.children}
    </_Nav>
  );
}

NavBar.Item = NavBarItem;
NavBar.Section = NavBarSection;

export default NavBar;
