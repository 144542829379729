import * as React from 'react';
import styled from 'styled-components';

import Logger from '../../../lib/observability/Logger';

const DEFAULT_TAG = 'em';

const _Container = styled.div`
  display: inline;
`;

const _EM = styled.strong`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-decoration: underline 0.2em;
  text-decoration-color: ${({ theme }) => theme.color.emphasisColor};
`;

const _DefaultEMRenderer = ({ children }: React.PropsWithChildren<unknown>) => <_EM>{children}</_EM>;

export interface ISearchResultHighlighterProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  emRenderer?: React.FC<{ children: React.ReactNode }>;
  emTag?: string;
  text: string;
}

/**
 * Component that highlights matched search terms based on embedded HTML tags.
 */
function SearchResultHighlighter({
  emRenderer = _DefaultEMRenderer,
  emTag = DEFAULT_TAG,
  text,
  ...props
}: ISearchResultHighlighterProps) {
  const [nodes, setNodes] = React.useState<NodeListOf<ChildNode> | null>(null);

  React.useEffect(() => {
    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(text, 'text/html');
      setNodes(doc.body.childNodes);
    } catch (e) {
      setNodes(null);
      Logger.warn(e as Error, `[SearchResultHighlighter] Failed to parse text: "${text}"`);
    }
  }, [text]);

  if (!nodes) {
    return null;
  }

  const children: JSX.Element[] = [];

  nodes.forEach((node, i) =>
    children.push(
      <React.Fragment key={i}>
        {node.nodeName === emTag.toUpperCase() ? emRenderer({ children: node.textContent }) : node.textContent}
      </React.Fragment>
    )
  );

  return <_Container {...props}>{children}</_Container>;
}

export default SearchResultHighlighter;
