import { AudienceType } from '../../___generated___/globalTypes';
import { CanonicalSize } from '../../providers/ThemeProvider/themes';
import { GlobeIcon, LockIcon, OrgIcon } from '../Icon';
import { IPickerOption } from '../Picker';

interface IAudienceOptionArgs {
  orgName?: string | null;
}

export function getAudienceOptions({ orgName }: IAudienceOptionArgs) {
  return {
    [AudienceType.PRIVATE]: {
      label: 'Restricted',
      desc: 'Visible only to specified people.',
      Icon: LockIcon,
      type: AudienceType.PRIVATE,
    },
    [AudienceType.ORG]: {
      label: orgName,
      desc: `Visible to ${orgName} and any specified people.`,
      Icon: OrgIcon,
      type: AudienceType.ORG,
    },
    [AudienceType.PUBLIC]: {
      label: 'Public',
      desc: 'Visible to anyone with the link.',
      Icon: GlobeIcon,
      type: AudienceType.PUBLIC,
    },
  };
}

interface IAudiencePickerOptionsArgs {
  orgName?: string | null;
  iconSize: CanonicalSize;
}

export function getAudiencePickerOptions({ orgName, iconSize }: IAudiencePickerOptionsArgs) {
  const pickerOptions: IPickerOption[] = Object.values(getAudienceOptions({ orgName }))
    // Filter out the org-public option if the viewer doesn't have an org.
    .filter(({ type }) => !(type === AudienceType.ORG && !orgName))
    .map(({ desc, Icon, label, type }) => ({
      desc,
      icon: Icon({ size: iconSize }),
      key: type,
      label: label ?? '',
    }));

  return pickerOptions;
}
