import { gql } from '@apollo/client';
import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { copyText } from '../../../lib/copyText';
import { CanonicalSizePx } from '../../../providers/ThemeProvider/themes';
import ActionsDropdown from '../../ActionsDropdown';
import Dropdown from '../../Dropdown';
import { CopyIcon, ForwardIcon, TargetIcon } from '../../Icon';

export const ACTION_BUTTON_SIZE = 'small';

export interface IAssistantMessageActionProps {
  debugContext?: string | null;
  onCopyMessage?: () => void;
  onShareMessage?: () => void;
}

function AssistantMessageAction({ debugContext, onCopyMessage, onShareMessage }: IAssistantMessageActionProps) {
  const theme = useTheme();

  return (
    <ActionsDropdown
      align="end"
      title="Actions"
      popperConfig={{ strategy: 'fixed' }}
      renderMenuOnMount
      size={ACTION_BUTTON_SIZE}
    >
      {onShareMessage && (
        <Dropdown.Item onClick={onShareMessage}>
          <ForwardIcon color={theme.color.secondaryColor} size={CanonicalSizePx.xxSmall} />
          Share...
        </Dropdown.Item>
      )}

      {onShareMessage && onCopyMessage && <Dropdown.Divider />}

      {onCopyMessage && (
        <Dropdown.Item onClick={onCopyMessage}>
          <CopyIcon color={theme.color.secondaryColor} size={CanonicalSizePx.xxSmall} />
          Copy
        </Dropdown.Item>
      )}

      {(onShareMessage || onCopyMessage) && debugContext ? <Dropdown.Divider /> : null}

      {debugContext && (
        <Dropdown.Item
          onClick={() => copyText(JSON.stringify(JSON.parse(debugContext ?? 'null'), null, 2)).catch(() => null)}
        >
          <TargetIcon color={theme.color.secondaryColor} size={CanonicalSizePx.xxSmall} />
          Copy debug context
        </Dropdown.Item>
      )}
    </ActionsDropdown>
  );
}

const _Container = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px 0;
`;

const _Content = styled.div<{ $justifyContent?: 'start' | 'end' }>`
  display: flex;
  flex: 1;
  justify-content: ${({ $justifyContent }) => ($justifyContent === 'end' ? 'flex-end' : 'flex-start')};
  min-width: 0;
`;

export interface IAssistantMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  debugContext?: string | null;
  justifyContent?: 'start' | 'end';
  onCopyMessage?: () => void;
  onShareMessage?: () => void;
}

function AssistantMessage({
  debugContext,
  justifyContent,
  children,
  onCopyMessage,
  onShareMessage,
  ...divProps
}: React.PropsWithChildren<IAssistantMessageProps>) {
  return (
    <_Container {...divProps}>
      <_Content $justifyContent={justifyContent}>{children}</_Content>
      {debugContext || onCopyMessage || onShareMessage ? (
        <AssistantMessageAction
          debugContext={debugContext}
          onCopyMessage={onCopyMessage}
          onShareMessage={onShareMessage}
        />
      ) : null}
    </_Container>
  );
}

AssistantMessage.fragment = gql`
  fragment CF_AssistantMessage on IAssistantMessage {
    id
    body
    contextFilters {
      id
      endTime
      scopedThreads {
        id
        name
      }
      startTime
    }
    state
  }
`;

export default AssistantMessage;
