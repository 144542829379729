import * as React from 'react';

import { ConfirmModal } from '../components/Modal';

interface IMeetingState {
  threadId: string | null;
  pendingThreadId: string | null;
  options?: { inviteMembers?: boolean };
}

export interface IAppMeetingStateManager {
  hideMeeting: () => void;
  meetingState: IMeetingState;
  showMeeting: (args: { threadId: string; options?: { confirmJoin?: boolean } & IMeetingState['options'] }) => void;
}

const _throwNotInitialized = () => {
  throw new Error('AppMeetingStateContext not initialized');
};

export const defaultContext: IAppMeetingStateManager = {
  hideMeeting: _throwNotInitialized,
  meetingState: { threadId: null, pendingThreadId: null },
  showMeeting: _throwNotInitialized,
};

export const AppMeetingStateContext = React.createContext<IAppMeetingStateManager>(defaultContext);

export default function AppMeetingStateProvider(props: React.PropsWithChildren<unknown>) {
  const [confirmJoinForThreadId, showConfirmJoinForThreadId] = React.useState<string | null>(null);
  const [meetingState, setMeetingState] = React.useState<IMeetingState>({ threadId: null, pendingThreadId: null });

  const showMeeting = React.useCallback(
    ({ options, threadId }: Parameters<IAppMeetingStateManager['showMeeting']>[0]) => {
      if (options?.confirmJoin && threadId) {
        showConfirmJoinForThreadId(threadId);
        setMeetingState((current) => ({ threadId: current.threadId, pendingThreadId: threadId, options }));
      } else {
        setMeetingState({ threadId, pendingThreadId: null, options }); // Note we deliberately set an object here to force re-render in case user clicks on same meeting again
      }
    },
    []
  );

  const hideMeeting = React.useCallback(() => {
    setMeetingState({ threadId: null, pendingThreadId: null });
    showConfirmJoinForThreadId(null);
  }, []);

  const appMeetingState = React.useMemo<IAppMeetingStateManager>(
    () => ({
      hideMeeting,
      meetingState,
      showMeeting,
    }),
    [hideMeeting, meetingState, showMeeting]
  );

  return (
    <AppMeetingStateContext.Provider value={appMeetingState}>
      {props.children}

      {/* Sometimes we need to show a confirmation prompt to user before joining video chat */}
      {!!confirmJoinForThreadId && (
        <ConfirmModal
          confirmLabel="Join"
          onConfirm={() => {
            setMeetingState({ threadId: confirmJoinForThreadId, pendingThreadId: null });
            showConfirmJoinForThreadId(null);
          }}
          onHide={() => showConfirmJoinForThreadId(null)}
          show={!!confirmJoinForThreadId}
          title="Join Video Chat"
        >
          Do you want to join the Video Chat now?
        </ConfirmModal>
      )}
    </AppMeetingStateContext.Provider>
  );
}

export const useAppMeetingState = (): IAppMeetingStateManager => {
  return React.useContext(AppMeetingStateContext);
};
