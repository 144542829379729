import * as React from 'react';
import * as Y from 'yjs';

import YDocBCTransport from '../lib/ydoc/YDocBCTransport';
import YDocSynchronizer from '../lib/ydoc/YDocSynchronizer';
import YDocWSTransport from '../lib/ydoc/YDocWSTransport';

import { useAuthManager } from './AuthProvider';

export interface IYDocTransportManager {
  /**
   * Websocket Transport: synchronizes state with back-end.
   */
  wsTransport?: YDocWSTransport;
}

export const YDocTransportContext = React.createContext<IYDocTransportManager>({});

export default function YDocTransportProvider(props: React.PropsWithChildren<unknown>) {
  const { accessToken } = useAuthManager();
  const [wsTransport, setWSTransport] = React.useState<YDocWSTransport | undefined>(undefined);

  React.useEffect(() => {
    const _wsTransport = accessToken ? new YDocWSTransport({ accessToken }) : undefined;
    setWSTransport(_wsTransport);

    return () => {
      _wsTransport?.disconnect();
      setWSTransport(undefined);
    };
  }, [accessToken]);

  const ydocTransportManager = React.useMemo<IYDocTransportManager>(() => ({ wsTransport }), [wsTransport]);
  return <YDocTransportContext.Provider value={ydocTransportManager}>{props.children}</YDocTransportContext.Provider>;
}

export const useYDocTransportManager = () => React.useContext(YDocTransportContext);

export interface IYDocSyncManagerProps {
  docId: string;
}

export function useYDocSynchronizer({ docId }: IYDocSyncManagerProps) {
  const [synchronizer, setSynchronizer] = React.useState<YDocSynchronizer | null>(null);
  const { wsTransport } = useYDocTransportManager();

  React.useEffect(() => {
    // We instantiate one BCTransport per synchronizer to make sure each
    // one uniquely receives external update events.
    const _bcTransport = new YDocBCTransport();
    const _synchronizer = new YDocSynchronizer(docId, new Y.Doc(), {
      bcTransport: _bcTransport,
      wsTransport,
    }).connect();
    _synchronizer.once('synced', () => setSynchronizer(_synchronizer));

    const _cleanup = () => {
      _synchronizer.disconnect();
      _bcTransport.disconnect();
      setSynchronizer(null);
    };

    // Add a beforeunload listener to flush any pending state updates
    window.addEventListener('beforeunload', _cleanup, { capture: true });
    return () => {
      window.removeEventListener('beforeunload', _cleanup, { capture: true });
      _cleanup();
    };
  }, [docId, wsTransport]);

  return synchronizer;
}
