import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';

function useBreakpoints() {
  const theme = useTheme();

  const isDesktopSize = useMediaQuery({ minWidth: theme.breakpoint.desktop });
  const isTabletOrMobileSize = useMediaQuery({ maxWidth: theme.breakpoint.tablet });
  const isMobileSize = useMediaQuery({ maxWidth: theme.breakpoint.mobile });

  return { isDesktopSize, isTabletOrMobileSize, isMobileSize };
}

export default useBreakpoints;
