import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { truncateText } from '../../../lib/css';
import { CanonicalSizePx } from '../../../providers/ThemeProvider/themes';
import { ProfileAvatar } from '../../Avatar';
import { CF_ProfileAvatar } from '../../Avatar/___generated___/ProfileAvatar.types';
import Card from '../../Card';
import CloseButton from '../../CloseButton';
import { ConditionalWrapper } from '../../lib/ConditionalWrapper';
import MaximizeThreadControl from '../../meeting/controls/MaximizeThreadControl';
import PaneHeader from '../../PaneHeader';
import { CF_ThreadOriginBreadcrumbs } from '../../PaneHeader/ThreadOriginBreadcrumbs/___generated___/ThreadOriginBreadcrumbs.types';

const _PaneHeader = styled(PaneHeader)`
  background-color: inherit;
  border-bottom: 1px solid ${({ theme }) => theme.color.secondaryBorder};
  height: unset;
  margin: 0 16px;
  max-width: 100%;
  min-height: unset;
  padding: 16px 0;
`;

const _BlockLink = styled(Link)`
  display: flex;
  ${truncateText()}
`;

const _CardTitle = styled(Card.Title)`
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.medium};
  min-width: 0;
`;

export interface IThreadPreviewCardHeaderProps {
  breadcrumbs?: CF_ThreadOriginBreadcrumbs;
  interactive?: boolean;
  name: string;
  onClose?: () => void;
  permalink: string;
  profile: CF_ProfileAvatar;
}

function ThreadPreviewCardHeader({
  breadcrumbs,
  interactive,
  name,
  onClose,
  permalink,
  profile,
}: IThreadPreviewCardHeaderProps) {
  const controls = interactive ? (
    <MaximizeThreadControl path={permalink} size="medium" />
  ) : (
    onClose && <CloseButton onClick={onClose} size="medium" />
  );

  return (
    <_PaneHeader
      avatar={<ProfileAvatar profile={profile} size={CanonicalSizePx.small} />}
      name={
        <ConditionalWrapper
          condition={!!interactive}
          wrapper={({ children }) => <_BlockLink to={permalink}>{children}</_BlockLink>}
        >
          <_CardTitle title={name}>{name || '(Untitled)'}</_CardTitle>
        </ConditionalWrapper>
      }
      context={
        breadcrumbs?.parents?.length ? (
          <PaneHeader.OriginBreadcrumbs interactive={false} thread={breadcrumbs} />
        ) : undefined
      }
    >
      {controls}
    </_PaneHeader>
  );
}

export default ThreadPreviewCardHeader;
