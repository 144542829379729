import { ButtonProps, useButtonProps } from '@restart/ui/Button';
import cx from 'classnames';
import * as React from 'react';

export interface IBaseButtonProps extends ButtonProps {
  /**
   * Manually set the visual state of the button to `:active`
   */
  active?: boolean;
  /**
   * Causes the browser to treat the linked URL as a download. For buttons with
   * an href only.
   *
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#download
   */
  download?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const BaseButton = React.forwardRef<HTMLElement, IBaseButtonProps>(
  ({ as, active = false, className, disabled = false, ...props }, ref) => {
    const [buttonProps, { tagName: Component }] = useButtonProps({
      tagName: as,
      disabled,
      ...props,
    });

    return (
      <Component
        {...buttonProps}
        {...props}
        className={cx(
          {
            active,
            disabled: props.href && disabled,
          },
          className
        )}
        disabled={disabled}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          if (disabled) {
            e.preventDefault();
            return;
          }

          props.onClick?.(e);
        }}
        ref={ref}
      />
    );
  }
);

BaseButton.displayName = 'BaseButton';

export default BaseButton;
