import * as React from 'react';

import Alert from '../Alert';
import Modal, { ModalProps } from '../Modal';
import OriginTypeahead from '../OriginTypeahead';
import Button from '../ui/Button';

import { IDiscussionParent, useEditOriginMutation, useIsChildDiscussionQuery } from './hooks';

export interface IEditOriginModalProps extends ModalProps {
  discussion: { id: string; subject?: string | null; origin?: IDiscussionParent | null };
}

function EditOriginModal({ discussion, ...props }: IEditOriginModalProps) {
  const [error, setError] = React.useState<string | null>(null);
  const [isSaving, setIsSaving] = React.useState(false);

  const defaultOriginIdRef = React.useRef<string | null>(discussion.origin?.id ?? null);
  const defaultOriginId = defaultOriginIdRef.current;

  const [originId, setOriginId] = React.useState<string | null>(defaultOriginId);

  React.useEffect(() => {
    if (defaultOriginId !== discussion.origin?.id) {
      defaultOriginIdRef.current = discussion.origin?.id ?? null;
      setOriginId(defaultOriginIdRef.current);
    }
  }, [defaultOriginId, discussion.origin?.id]);

  const [editOrigin] = useEditOriginMutation();
  const [isChild] = useIsChildDiscussionQuery(discussion.id);

  // TODO filter children of the current discussion from this list.
  const excludeIds = [discussion.id, originId].filterNullish();

  async function onSave() {
    setIsSaving(true);

    if (originId !== defaultOriginId) {
      await editOrigin(discussion.id, originId);
    }

    setIsSaving(false);
    props.onHide?.();
  }

  function onExit() {
    setOriginId(defaultOriginId);
    props.onHide?.();
  }

  async function validateAndSetId(optionId: string, optionName: string) {
    const result = await isChild(optionId);
    if (result?.data?.discussion?.isChild) {
      setError(`"${optionName}" is a descendant of the current discussion. Please select a different option.`);
      setOriginId(null);
    } else {
      setError(null);
      setOriginId(optionId);
    }
  }

  return (
    <Modal {...props} onExit={onExit}>
      <Modal.Header closeButton>
        <Modal.Title>Change discussion parent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert
            variant="danger"
            onClose={() => {
              setError(null);
              setOriginId(null);
            }}
          >
            {error}
          </Alert>
        )}
        <OriginTypeahead
          disabled={isSaving}
          excludeIds={excludeIds}
          onRemove={() => {
            setError(null);
            setOriginId(null);
          }}
          onSelect={validateAndSetId}
          selectedId={originId}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cancel</Button>
        <Button disabled={isSaving || !!error} onClick={onSave} type="primary">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditOriginModal;
