import Logger from '../observability/Logger';

export interface IAudioPlayerOptions {
  repeatCount?: number;
}

export interface IAudioPlayer {
  stop: () => void;
}

export default class AudioUtils {
  /**
   *
   * @param options.repeatCount Number of times to repeat the audio. Default is 0.
   * @returns
   */
  static play(resource: string, options?: IAudioPlayerOptions): IAudioPlayer {
    const audio = new Audio(resource);
    let _repeatCount = options?.repeatCount ?? 0;

    const _onEnded = () => {
      if (_repeatCount--) {
        _play();
        return;
      }
    };

    const _play = () => {
      audio.addEventListener('ended', _onEnded, { once: true });
      audio.play().catch((e) => {
        Logger.info(e, `[AudioUtils] Could not play ${resource}`);
        _stop();
      });
    };

    const _stop = () => {
      audio.removeEventListener('ended', _onEnded);
      audio.pause();
      audio.currentTime = 0;
    };

    // Begin playback as soon as enough content has lodaded to play
    audio.addEventListener('canplaythrough', _play, { once: true });

    // Return an interface to stop the audio as needed
    return { stop: _stop };
  }
}
