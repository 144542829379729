import * as React from 'react';
import OverlayTrigger, { OverlayTriggerProps } from 'react-bootstrap/OverlayTrigger';
import RBTooltip, { TooltipProps } from 'react-bootstrap/Tooltip';
import styled from 'styled-components';

const _Tooltip = styled(RBTooltip)`
  .tooltip-inner {
    border-radius: ${({ theme }) => theme.borderRadius.small};
  }

  /* Hack to disable tooltip rendering on devices that don't support hover */
  @media (hover: none) {
    display: none;
  }
`;

export type TooltipPlacement = OverlayTriggerProps['placement'];

export interface ITooltipProps extends Omit<OverlayTriggerProps, 'overlay'> {
  /**
   * Id used for a11y. Optional but recommended.
   */
  id?: TooltipProps['id'];
  /**
   * The content displayed in the tooltip overlay.
   */
  title: TooltipProps['children'];
  /**
   * Disable the tooltip from appearing
   */
  disable?: boolean;
}

function Tooltip({ children, flip = true, id, placement = 'top', title, disable, ...props }: ITooltipProps) {
  return (
    <OverlayTrigger
      {...props}
      flip={flip}
      overlay={!disable ? <_Tooltip id={id}>{title}</_Tooltip> : <span />}
      placement={placement}
    >
      {children}
    </OverlayTrigger>
  );
}

export default Tooltip;
