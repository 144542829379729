import * as React from 'react';
import styled from 'styled-components';

import LegacyPalette from '../../providers/ThemeProvider/color-palettes/LegacyPalette';
import { PlayCircleIcon } from '../Icon';

const _PlayOverlay = styled.div`
  align-items: center;
  color: ${LegacyPalette.white};
  display: flex;
  filter: drop-shadow(0 0 4px ${LegacyPalette.black}); /* Make the icon visible on light backgrounds */
  font-size: ${({ theme }) => theme.fontSize.h3};
  inset: 0;
  justify-content: center;
  line-height: 1;
  opacity: 0.8;
  position: absolute;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

function MediaPlayIconOverlay() {
  return (
    <_PlayOverlay>
      <PlayCircleIcon size={80} strokeWidth={1} />
    </_PlayOverlay>
  );
}

export default MediaPlayIconOverlay;
